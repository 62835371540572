import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'scoreLevelTitle',
})
export class ScoreLevelTitlePipe implements PipeTransform {
  transform(value: number): string {
    let title = '';
    if (value > 0 && value < 250) {
      title = 'beginner';
    }
    if (value >= 250 && value < 500) {
      title = 'intermediate';
    }
    if (value >= 500 && value < 750) {
      title = 'advanced';
    }
    if (value >= 750) {
      title = 'sensei';
    }

    return title;
  }
}
