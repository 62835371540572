<span class="notification-badge cursor-pointer">
  <i class="badge badge-primary" *ngIf="!notificationIsRead"></i>

  <span *ngIf="notificationType === 'ContestProblemCreate'">
    <i class="bi bi-info-circle icon-2x" title="Problem Created"></i>
  </span>

  <span *ngIf="notificationType === 'ContestStarted'">
    <i class="bi bi-info-circle icon-2x" title="General Leaderboard"></i>
  </span>

  <span *ngIf="notificationType === 'ContestMaintenanceMode'">
    <i class="bi bi-bell icon-2x" title="Contest Maintenance Mode"></i>
  </span>

  <span *ngIf="notificationType === 'GLeaderboardEvaluation'">
    <i class="bi bi-info-circle icon-2x" title="General Leaderboard"></i>
  </span>

  <span *ngIf="notificationType === 'GLeaderboardRollback'">
    <i class="bi bi-info-circle icon-2x" title="Rollback General Leaderboard"></i>
  </span>

  <span *ngIf="notificationType === 'ProblemAssignRole'">
    <i class="bi bi-info-circle icon-2x" title="Problem Assign Role"></i>
  </span>

  <span class="contestSignUp" *ngIf="notificationType === 'ContestSignUp'">
    <i class="bi bi-info-circle icon-2x" title="Contest SignUp"></i>
  </span>

  <span class="contestSignUpStatusChange" *ngIf="notificationType === 'ContestSignUpStatusChange'">
    <i class="bi bi-info-circle icon-2x" title="Contest SignUp Status Changed"></i>
  </span>

  <span class="contestAnnouncement" *ngIf="notificationType === 'ContestAnnouncement'">
    <i class="bi bi-bell icon-2x" title="Contest Announcement"></i>
  </span>

  <span
    class="contestClarificationCreate"
    *ngIf="notificationType === 'ContestClarificationCreate'"
  >
    <i class="bi bi-info-circle icon-2x" title="Contest Clarification Created"></i>
  </span>

  <span
    class="contestClarificationAnswer"
    *ngIf="notificationType === 'ContestClarificationAnswer'"
  >
    <i class="bi bi-info-circle icon-2x" title="Contest Clarification Answered"></i>
  </span>

  <span class="contestDiscussionReply" *ngIf="notificationType === 'ContestDiscussionReply'">
    <i class="bi bi-chat-text icon-2x" title="Contest Discussion Replied"></i>
  </span>
</span>
