import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateAgoPipe } from './date/date-ago.pipe';
import { SafeHtmlPipe } from './bypass-securities/safe-html.pipe';
import { SafeUrlPipe } from './bypass-securities/safe-url.pipe';
import { MillisecondHumanityPipe } from './date/millisecond-humanity.pipe';
import { DateMinimalPipe } from './date/date-minimal.pipe';
import { DateFromUtcPipe } from './date/date-from-utc.pipe';
import { DigitalClockPipe } from './date/second-digitally.pipe';



@NgModule({
  declarations: [DateAgoPipe, SafeHtmlPipe, SafeUrlPipe, MillisecondHumanityPipe, DateMinimalPipe, DateFromUtcPipe, DigitalClockPipe],
  imports: [
    CommonModule
  ],
  exports: [
    DateAgoPipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    MillisecondHumanityPipe,
    DateMinimalPipe,
    DateFromUtcPipe,
    DigitalClockPipe
  ]
})
export class PipeModule { }
