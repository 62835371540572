import { SpilitTextIntoSpecificLengthPipe } from './../../utils/pipes/spilit-text-into-specific-length.pipe';
import { MessageService } from 'primeng/api';
import {
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';

import { FormGroup } from '@angular/forms';
import { NotificationService } from '../../services';
import { SubSink } from 'subsink';
import { SubheaderService } from '../../../../../shared/templates/_metronic/partials/layout';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-notification-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  providers: [MessageService, SpilitTextIntoSpecificLengthPipe],
})
export class ListComponent implements OnInit, OnDestroy, OnChanges {
  public pageSizeGroup: FormGroup;
  public currentPage = 1;
  public pageSize = 10;
  public notifications: any[] = [];
  public notificationsCount: number;
  public unReadNotificationsCount = 0;
  public isLoading = false;
  public deleteAllButtonIsEnabled = false;
  public readAllButtonIsEnabled = false;
  private _subs = new SubSink();

  constructor(
    private _cdr: ChangeDetectorRef,
    private _msgService: MessageService,
    private subheaderService: SubheaderService,
    private _notificationService: NotificationService,
    private _spilitTextIntoSpecificLengthPipe: SpilitTextIntoSpecificLengthPipe
  ) {
    this._subs.sink = this._notificationService.onChangeNotification.subscribe(() => {
      this._getLatestNotifications();
    });
  }

  ngOnInit(): void {
    this._initSubHeader();
    this._getLatestNotifications();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._initSubHeader();
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  public handleVisitNotification(notification: any): void {
    const URL = notification.notificationParameters.find(x => x.key.toLowerCase() == 'url');
    if (!notification.isRead) {
      this.isLoading = true;
      this._subs.sink = this._notificationService
        .updateOne(notification.id, {
          isRead: true,
        })
        .pipe(
          finalize(() => {
            this.isLoading = false;
            this._cdr.detectChanges();
          })
        )
        .subscribe(result => {
          const idx = this.notifications.findIndex(x => x.id == notification.id);
          this.notifications[idx].isRead = true;
          this._notificationService.onChangeNotification.emit();
          if (URL) {
            window.location.href = URL.value;
          }
        });
    } else if (notification.isRead && URL) {
      window.location.href = URL.value;
    }
  }

  public deleteNotification(notification: any): void {
    this.isLoading = true;
    this._subs.sink = this._notificationService
      .deleteNotificationById(notification.id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this._cdr.detectChanges();
        })
      )
      .subscribe(result => {
        this._getLatestNotifications();
        this._notificationService.onChangeNotification.emit();
      });
  }

  public handlePageNumberChange(page): void {
    this.currentPage = page;
    this._getLatestNotifications();
  }

  public handlePageSizeChange(event): void {
    this.currentPage = 1;
    this.pageSize = event.target.value;
    this._getLatestNotifications();
  }

  public readAllNotifications(): void {
    this.isLoading = true;
    this._subs.sink = this._notificationService
      .readAll()
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this._cdr.detectChanges();
        })
      )
      .subscribe(() => {
        this._msgService.add({
          severity: 'success',
          detail: 'All notifications status changed to read successfully',
        });
        this._getLatestNotifications();
      });
  }

  public deleteAllNotifications(): void {
    this.isLoading = true;
    this._subs.sink = this._notificationService
      .deleteAll()
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this._cdr.detectChanges();
        })
      )
      .subscribe(() => {
        this._msgService.add({
          severity: 'success',
          detail: 'All notifications deleted successfully',
        });
        this._getLatestNotifications();
      });
  }

  private _initSubHeader(): void {
    this.subheaderService.setTitle('::NotificationList');
    this.subheaderService.setBreadcrumbs([
      {
        title: '::NotificationList',
        linkText: '::NotificationList',
        linkPath: '/notification/list',
      },
    ]);
  }

  private _getLatestNotifications() {
    const skipCount = (this.currentPage - 1) * this.pageSize;
    this._subs.sink = this._notificationService
      .getAll('None', skipCount, this.pageSize)
      .subscribe(data => {
        this.notificationsCount = data.totalCount;
        data.items.forEach((item, index) => {
          const contestName = item.notificationParameters.find(x => x.key == 'contestName')?.value;
          if (item.notificationType.eventType === 'ContestMaintenanceMode') {
            const isActive = item.notificationParameters.find(x => x.key == 'isActive')?.value;
            const userName = item.notificationParameters.find(x => x.key == 'userName')?.value;
            const activeMessage = item.notificationParameters.find(
              x => x.key == 'activeMessage'
            )?.value;
            item.notificationType.message = item.notificationType.message
              .replace('{isActive}', isActive)
              .replace('{contestName}', contestName)
              .replace('{userName}', userName)
              .replace('{activeMessage}', activeMessage);
          }
          if (item.notificationType.eventType === 'ContestSignUpStatusChange') {
            const status = item.notificationParameters.find(x => x.key == 'status')?.value;
            item.notificationType.message = item.notificationType.message
              .replace('{status}', status)
              .replace('{contestName}', contestName);
          }
          if (item.notificationType.eventType === 'GLeaderboardEvaluation') {
            const score = item.notificationParameters.find(x => x.key == 'score')?.value;
            const title = item.notificationParameters.find(x => x.key == 'title')?.value;
            item.notificationType.message = item.notificationType.message
              .replace('{score}', score)
              .replace('{title}', title)
              .replace('{contestName}', contestName);
          }
          const userName = item.notificationParameters.find(x => x.key == 'userName')?.value;
          if (item.notificationType.eventType === 'ProblemAssignRole') {
            const role = item.notificationParameters.find(x => x.key == 'role')?.value;
            const problemName = item.notificationParameters.find(
              x => x.key == 'problemName'
            )?.value;
            item.notificationType.message = item.notificationType.message
              .replace('{role}', role)
              .replace('{userName}', userName)
              .replace('{problemName}', problemName);
          } else {
            item.notificationType.message = item.notificationType.message
              .replace('{userName}', userName)
              .replace('{contestName}', contestName);
          }
          let modifiedMessage = this._spilitTextIntoSpecificLengthPipe.transform(
            item.notificationType.message
          );
          const URL = item.notificationParameters.find(x => x.key.toLowerCase() == 'url');
          if (URL) {
            modifiedMessage = modifiedMessage.replace(
              contestName,
              `<a href="${URL.value}" class="text-primary cursor-pointer font-weight-bold">${contestName}</a>`
            );
          }
          if (userName) {
            data.items[index].userName = userName;
            modifiedMessage = modifiedMessage.replace(
              userName,
              `<a href="/profile/${userName}/overview" class="text-primary cursor-pointer font-weight-bold">${userName}</a>`
            );
          }
          item.notificationType.message = modifiedMessage;
        });

        this.notifications = data.items;
        this.deleteAllButtonIsEnabled = this.notificationsCount > 0;
        this._getUnreadNotificationCount();
      });
  }

  private _getUnreadNotificationCount() {
    this._subs.sink = this._notificationService.getUnreadNotificationsCount().subscribe(data => {
      this.unReadNotificationsCount = data.totalCount;
      this.readAllButtonIsEnabled = this.unReadNotificationsCount > 0;
    });
  }
}
