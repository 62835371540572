<!--begin::Logo-->
<a routerLink="/">
  <img alt="Logo" class="logo" [attr.src]="headerLogo + '?v=1.1'" loading="lazy"/>
</a>
<!--end::Logo-->

<!--begin::Toolbar-->
<div class="d-flex align-items-center">
  <ng-container *ngIf="asideSelfDisplay">
    <!--begin::Aside Mobile Toggle-->
    <button class="btn p-0 burger-icon burger-icon-left" id="kt_aside_mobile_toggle">
      <span></span>
    </button>
    <!--end::Aside Mobile Toggle-->
  </ng-container>

  <ng-container *ngIf="headerMenuSelfDisplay">
    <!--begin::Header Menu Mobile Toggle-->
    <button class="btn p-0 burger-icon ml-4" id="kt_header_mobile_toggle">
      <span id="kt_header_mobile_toggle_span"></span>
    </button>
    <!--end::Header Menu Mobile Toggle-->
  </ng-container>

  <!--begin::Topbar Mobile Toggle-->
  <button class="btn btn-hover-text-primary p-0 ml-2" id="kt_header_mobile_topbar_toggle">
    <img src="./assets/media/users/avatar.png" id="kt_header_mobile_topbar_toggle__image" loading="lazy"/>
  </button>
  <!--end::Topbar Mobile Toggle-->
</div>
<!--end::Toolbar-->
