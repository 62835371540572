<div class="row">
  <div class="col-md-12">
    <div class="card card-custom pt-3">
      <div class="card-body">
        <p *ngIf="unReadNotificationsCount > 0">
          <span
            class="badge badge-primary"
            id="un-read-notifications-count"
            title="unread notifications"
            >{{ unReadNotificationsCount }}</span
          >
        </p>
        <div class="d-flex justify-content-end">
          <button
            class="btn btn-secondary mr-3"
            [disabled]="!readAllButtonIsEnabled"
            (click)="readAllNotifications()"
          >
            Read All
          </button>
          <button
            class="btn btn-primary"
            [disabled]="!deleteAllButtonIsEnabled"
            (click)="deleteAllNotifications()"
          >
            Delete All
          </button>
        </div>
        <div class="table-responsive">
          <table
            class="table table-head-custom table-head-bg table-borderless table-vertical-center"
          >
            <tbody>
              <tr class="border-bottom" *ngFor="let notification of notifications">
                <td class="pl-0 py-8 col-8">
                  <div class="d-flex align-items-center">
                    <app-notification-icon
                      class="cursor-pointer"
                      (click)="handleVisitNotification(notification)"
                      [notificationType]="notification.notificationType.eventType"
                      [notificationIsRead]="notification.isRead"
                    ></app-notification-icon>
                    <span
                      class="notification-message cursor-pointer"
                      (click)="handleVisitNotification(notification)"
                      [ngClass]="!notification.isRead ? 'unread-message' : ''"
                      [innerHTML]="notification.notificationType.message"
                    >
                    </span>
                  </div>
                </td>
                <td class="col-3">
                  <p class="m-0">
                    <b>Posted on:</b> {{ notification.creationTime | date : 'dd/MM/yyyy hh:mm a' }}
                  </p>
                  <p class="m-0" *ngIf="notification.hasOwnProperty('userName')">
                    <b>Posted by:</b>
                    <a
                      [routerLink]="'/profile/' + notification.userName + '/overview'"
                      class="text-primary cursor-pointer font-weight-bold ml-2"
                      >{{ notification.userName }}</a
                    >
                  </p>
                </td>
                <td class="col-1">
                  <p class="d-flex justify-content-center">
                    <i
                      title="Remove notification"
                      class="fa fa-trash ml-1 cursor-pointer text-secondary"
                      (click)="deleteNotification(notification)"
                    ></i>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="text-center my-5" *ngIf="notifications.length === 0">
          <i class="bi bi-info-circle icon-5x"></i>
          <span class="d-block text-dark-50">{{ '::NoRecordFound' | abpLocalization }}</span>
        </div>
        <div class="d-flex justify-content-between align-items-center flex-wrap mt-2">
          <div class="d-flex flex-wrap py-2 mr-3">
            <ngb-pagination
              [(page)]="currentPage"
              [pageSize]="pageSize"
              [collectionSize]="notificationsCount"
              (pageChange)="handlePageNumberChange($event)"
            ></ngb-pagination>
          </div>
          <div class="d-flex align-items-center py-3">
            <select
              class="form-control form-control-sm font-weight-bold mr-4 border-0 bg-light"
              style="width: 75px"
              name="pageSize"
              placeholder="PageSize"
              (change)="handlePageSizeChange($event)"
            >
              <option value="10" selected>10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <span class="text-muted">
              {{ '::Showing' | abpLocalization }} {{ currentPage * pageSize - pageSize + 1 }} -
              {{
                (currentPage + 1) * pageSize - pageSize > notificationsCount
                  ? notificationsCount
                  : (currentPage + 1) * pageSize - pageSize
              }}
              {{ '::of' | abpLocalization }} {{ notificationsCount }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-spinner *ngIf="isLoading"></app-spinner>
<p-toast position="bottom-right" [preventOpenDuplicates]="true"></p-toast>
