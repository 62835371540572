import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'spilitTextIntoSpecificLength',
})
export class SpilitTextIntoSpecificLengthPipe implements PipeTransform {
  transform(text: string): string {
    if (text.length == 0) return '';
    const spilitedText = text.match(/.{1,65}(?= |$)/g);
    let result = '';
    spilitedText.forEach((text, index) => {
      result += index != spilitedText.length - 1 ? text + ' <br/> ' : text;
    });
    return result;
  }
}
