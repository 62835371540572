import { Injectable } from '@angular/core';
import { ClientStorageKeys } from './client-storage.keys';

@Injectable({
  providedIn: 'root'
})
export class ClientStorageService {

  constructor() { }

  getValue(key: ClientStorageKeys): string {
    return localStorage.getItem(key);
  }

  setValue(key: ClientStorageKeys, value: string): void{
    localStorage.setItem(key, value);
  }

  removeValue(key: ClientStorageKeys): void{
    localStorage.removeItem(key);
  }

  clearValues(): void {
    localStorage.clear();
  }
}
