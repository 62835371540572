import { Directive, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { EnvironmentService } from '@abp/ng.core';
import { UnsplashService } from '../../services/api/unsplash.service';

@Directive({
  selector: '[appRandomBackgroundImage]'
})
export class RandomBackgroundImageDirective implements OnChanges{

  images: string[];

  constructor(private el: ElementRef, private unsplashService: UnsplashService, environmentSystem: EnvironmentService) {
    const environment: any = environmentSystem.getEnvironment();
    this.images = environment.randomBackgroundImage;
    this.setImage();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setImage();
  }

  setImage(): void{
    this.unsplashService.getImage()
      .then((randomImage) => {
        this.el.nativeElement.style.backgroundImage = 'url("' + randomImage + '")';
      })
      .catch(() => {
        const randomImage = this.images[Math.floor(Math.random() * this.images.length)];
        this.el.nativeElement.style.backgroundImage = 'url("' + randomImage + '")';
      });
  }

}
