import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
 import { DataBaseFillIconComponent } from './database-fill-icon.component';

@NgModule({
  declarations: [DataBaseFillIconComponent],
  exports: [DataBaseFillIconComponent],
  imports: [CommonModule],
})
export class CustomIconsModule {}
