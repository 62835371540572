<!--begin::Container-->
<div class="d-flex flex-column flex-md-row align-items-center justify-content-between w-100">
<!--  [ngClass]="footerContainerCSSClasses"|<-->

  <!--begin::Copyright-->
  <div class="text-dark float-left" [innerHTML]="'::FooterShortCopyrightText' | abpLocalization">
  </div>
  <!--end::Copyright-->

  <!--begin::Nav-->
  <div class="nav nav-dark float-right">
    <a routerLink='/page/privacypolicy' target='_blank' class="nav-link pl-0 pr-20">{{'::Privacy' | abpLocalization}}</a>
    <a routerLink='/page/legal' target='_blank' class="nav-link pl-0 pr-20">{{'::Legal' | abpLocalization}}</a>
    <a routerLink='/page/contact-us' target='_blank' class="nav-link pl-0 pr-0">{{"::ContactUs" | abpLocalization}}</a>
  </div>
  <!--end::Nav-->
</div>
<!--end::Container-->
