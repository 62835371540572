<button
  mat-icon-button
  [matMenuTriggerFor]="notificationList"
  class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pulse pulse-primary bg-transparent-h"
>
  <i class="fa fa-bell"></i>
  <span
    *ngIf="unReadNotificationsCount > 0"
    class="badge badge-primary"
    id="unread-notifications-count"
  >
    {{ unReadNotificationsCount }}
  </span>
</button>

<mat-menu #notificationList="matMenu">
  <div class="navi navi-spacer-x-0 pt-5">
    <ng-container *ngFor="let notification of notifications">
      <span class="navi-item px-8 notification-item">
        <div class="navi-link">
          <div class="navi-icon mr-2">
            <app-notification-icon
              (click)="handleVisitNotification(notification)"
              [notificationType]="notification.notificationType.eventType"
              [notificationIsRead]="true"
            ></app-notification-icon>
          </div>
          <div class="navi-text">
            <div
              class="notification-message"
              [ngClass]="{ 'text-muted': notification.isRead }"
              [title]="notification.notificationType.message"
            >
              <span
                class="notification-message cursor-pointer"
                (click)="handleVisitNotification(notification)"
              >
                {{ notification.notificationType.message | slice : 0 : 55 }}
                {{ notification.notificationType.message.length > 55 ? '...' : '' }}
              </span>
            </div>
          </div>
        </div>
      </span>
    </ng-container>
    <div class="text-center my-5" *ngIf="notifications.length === 0">
      <i class="bi bi-info-circle icon-5x"></i>
      <span class="d-block text-dark-50">{{ '::NoRecordFound' | abpLocalization }}</span>
    </div>
    <div class="navi-separator mt-3"></div>
    <div class="navi-footer px-8 py-5">
      <a
        class="btn btn-light-primary font-weight-bold cursor-pointer"
        routerLink="/notifications/list"
      >
        <i class="bi bi-box-arrow-right"></i>
        <!-- todo: use real source here -->
        See All Notifications
      </a>
    </div>
  </div>
</mat-menu>
