import { Pipe, PipeTransform } from '@angular/core';
import { momentTimeZone } from '../../constants/moment/moment-timezone';

@Pipe({
  name: 'dateFromUtc'
})
export class DateFromUtcPipe implements PipeTransform {

  transform(value: Date, ...args: any[]): Date {
    const currentTimeZone = momentTimeZone.tz.guess(true);
    return momentTimeZone.tz(momentTimeZone(value).format('YYYY/MM/DD HH:mm') , 'UTC').tz(currentTimeZone).toDate();
  }

}
