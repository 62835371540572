import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'dateFormatter',
  pure: true,
})
export class DateFormatterPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  // todo: real resource should be replace here later on
  transform(value: any, args?: any): any {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      // if (seconds < 29) return this.translateService.instant('SHARED.JUST_NOW');
      if (seconds < 29) return 'Just now';
      const intervals = {
        year: 31536000,
        month: 2592000,
        week: 604800,
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1,
      };
      let counter;
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i]);
        // const time = this.translateService.instant(`SHARED.${i.toUpperCase()}`);
        const time = i;
        if (counter > 0)
          if (counter === 1) {
            // return counter + ' ' + time + this.translateService.instant('SHARED.AGO'); // singular (1 day ago)
            return counter + ' ' + time + ' ago'; // singular (1 day ago)
          } else {
            // return counter + ' ' + time + this.translateService.instant('SHARED.AGO_PLURAL'); // plural (2 days ago)
            return counter + ' ' + time + 's ago'; // plural (2 days ago)
          }
      }
    }

    return value;
  }
}
