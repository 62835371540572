<ul class="menu-nav" [ngClass]="ulCSSClasses">
  <li class="menu-item menu-item-rel" [ngClass]="{ 'menu-item-here': isInPracticeArea() }">
    <a class="menu-link" routerLink="/problem/list">
      <span class="menu-text">{{ '::Practice' | abpLocalization }}</span>
    </a>
  </li>

  <li class="menu-item menu-item-rel" [ngClass]="{ 'menu-item-here': isInContestArea() }">
    <a class="menu-link" routerLink="/contest/list">
      <span class="menu-text">{{ '::Contest' | abpLocalization }}</span>
    </a>
  </li>

  <li class="menu-item menu-item-rel" [ngClass]="{ 'menu-item-here': isInTrainingArea() }">
    <a class="menu-link" routerLink="/training">
      <span class="menu-text">{{ '::Training' | abpLocalization }}</span>
    </a>
  </li>

  <li class="menu-item menu-item-rel" [ngClass]="{ 'menu-item-here': isInGeneralLeadboardArea() }">
    <a class="menu-link" routerLink="/leaderboard">
      <span class="menu-text">{{ '::Leaderboard' | abpLocalization }}</span>
    </a>
  </li>

  <li class="menu-item menu-item-rel" *abpPermission="'AbpIdentity.Roles'">
    <a class="menu-link" mat-button [matMenuTriggerFor]="administration">
      <span class="menu-text">{{ 'AbpUiNavigation::Menu:Administration' | abpLocalization }}</span>
    </a>

    <mat-menu #administration="matMenu">
      <button mat-menu-item [matMenuTriggerFor]="IdentityManagement">
        {{ 'AbpIdentity::Menu:IdentityManagement' | abpLocalization }}
      </button>
      <button mat-menu-item [matMenuTriggerFor]="TenantManagement">
        {{ 'AbpTenantManagement::Menu:TenantManagement' | abpLocalization }}
      </button>
    </mat-menu>

    <mat-menu #IdentityManagement="matMenu">
      <button mat-menu-item routerLink="/identity/roles">
        {{ 'AbpIdentity::Roles' | abpLocalization }}
      </button>
      <button mat-menu-item routerLink="/identity/users">
        {{ 'AbpIdentity::Users' | abpLocalization }}
      </button>
    </mat-menu>

    <mat-menu #TenantManagement="matMenu">
      <button mat-menu-item routerLink="/tenant-management/tenants">
        {{ 'AbpTenantManagement::Tenants' | abpLocalization }}
      </button>
    </mat-menu>
  </li>
</ul>
