import { CommonModule } from '@angular/common';
import { DateFormatterPipe } from './date-formatter.pipe';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [DateFormatterPipe],
  imports: [CommonModule],
  exports: [DateFormatterPipe],
})
export class DateFormatterModule {}
