import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(private title: Title, private meta: Meta) {}

  updateTitle(title: string): void {
    if (title) {
      this.title.setTitle(title);
    }
  }

  updateDescription(description: string): void {
    if (description) {
      this.meta.updateTag({ name: 'description', content: description });
    }
  }

  setCanonicalTag(newCanonicalUrl: string): void {
    const canonicalTag = this.meta.getTag('rel="canonical"');
    if (canonicalTag) {
      this.meta.updateTag({ rel: 'canonical', href: newCanonicalUrl });
    } else {
      this.meta.addTag({ rel: 'canonical', href: newCanonicalUrl });
    }
  }
}
