import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NotificationIconComponent } from './notification-icon.component';

@NgModule({
  declarations: [NotificationIconComponent],
  imports: [CommonModule],
  exports: [NotificationIconComponent],
})
export class NotificationIconModule {}
