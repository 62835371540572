import { Injectable } from '@angular/core';
import { ReplaceableComponentsService } from '@abp/ng.core';
import { eThemeBasicComponents } from '@abp/ng.theme.basic';
import { ApplicationLayoutComponent } from '../../../shared/templates/_metronic/layouts/application-layout/application-layout.component';
import { AccountLayoutComponent } from '../../../shared/templates/_metronic/layouts/account-layout/account-layout.component';
import { EmptyLayoutComponent } from '../../../shared/templates/_metronic/layouts/empty-layout/empty-layout.component';

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  constructor(private replaceableComponents: ReplaceableComponentsService) {}

  replaceComponent() {
    this.replaceableComponents.add({
      component: ApplicationLayoutComponent,
      key: eThemeBasicComponents.ApplicationLayout,
    });
    this.replaceableComponents.add({
      component: AccountLayoutComponent,
      key: eThemeBasicComponents.AccountLayout,
    });
    this.replaceableComponents.add({
      component: EmptyLayoutComponent,
      key: eThemeBasicComponents.EmptyLayout,
    });
  }
}
