import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RandomBackgroundImageDirective } from './random-background-image/random-background-image.directive';
import { MathJaxDirective } from './math-jax/math-jax.directive';
import { FindObjectDirective } from './find-object/find-object.directive';
import { ColorifyObjectDirective } from './colorify-object/colorify-object.directive';
import { MarkJaxDirective } from './mark-jax/mark-jax.directive';
import { MarkJaxForTitleDirective } from './mark-jax-for-title/mark-jax-for-title.directive';



@NgModule({
  declarations: [RandomBackgroundImageDirective, MathJaxDirective, FindObjectDirective, ColorifyObjectDirective, MarkJaxDirective,MarkJaxForTitleDirective],
  exports: [
    MathJaxDirective,
    MarkJaxDirective,
    RandomBackgroundImageDirective,
    FindObjectDirective,
    ColorifyObjectDirective,
    MarkJaxForTitleDirective
  ],
  imports: [
    CommonModule
  ]
})
export class DirectivesModule { }
