<ul class="menu-nav" [ngClass]="ulCSSClasses">
  <ng-container
    *ngFor="let route of routesService.visible$ | async; trackBy: trackByFn"
    [ngTemplateOutlet]="isDropdown(route) ? dropdownLink : defaultLink"
    [ngTemplateOutletContext]="{ $implicit: route }"
  >
  </ng-container>

  <ng-template #defaultLink let-route>
    <li class="menu-item menu-item-rel" *abpPermission="route.requiredPolicy">
      <a class="menu-link" [routerLink]="[route.path]">
        <span class="menu-text">{{ route.name | abpLocalization }}</span>
        <ng-container *ngIf="rootArrowEnabled">
          <i class="menu-arrow"></i>
        </ng-container>
      </a>
    </li>
  </ng-template>

  <ng-template #dropdownLink let-route>
    <ng-container *ngIf="route.children?.length">
      <li
        #navbarRootDropdown
        [attr.data-menu-toggle]="headerMenuDesktopToggle"
        aria-haspopup="true"
        class="menu-item menu-item-submenu menu-item-rel"
        routerLinkActive="menu-item-active"
        *abpPermission="route.requiredPolicy"
      >
        <a
          class="menu-link menu-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span class="menu-text">{{ route.name | abpLocalization }}</span>
          <i class="menu-arrow"></i>
        </a>
        <div
          #routeContainer
          class="menu-submenu menu-submenu-classic menu-submenu-left"
        >
          <ng-container
            *ngTemplateOutlet="forTemplate; context: { $implicit: route }"
          ></ng-container>
        </div>
      </li>
    </ng-container>
  </ng-template>

  <ng-template #forTemplate let-route>
    <ng-container *ngFor="let child of route.children">
      <ng-template
        [ngTemplateOutlet]="child.children?.length ? dropdownChild : defaultChild"
        [ngTemplateOutletContext]="{ $implicit: child }"
      ></ng-template>
    </ng-container>
  </ng-template>

  <ng-template #defaultChild let-child>
    <div class="menu-item" *abpPermission="child.requiredPolicy">
      <a class="menu-link" [routerLink]="[child.path]" (click)="closeDropdown()">
        <i class="menu-bullet menu-bullet-dot"><span></span></i>
        <span class="menu-text">{{ child.name | abpLocalization }}</span>
      </a>
    </div>
  </ng-template>

  <ng-template #dropdownChild let-child>
    <ul class="menu-subnav">
      <li
        class="menu-item menu-item-submenu"
        data-menu-toggle="hover"
        aria-haspopup="true"
        *abpPermission="child.requiredPolicy"
      >
        <a
          class="menu-link menu-toggle"
        >
          <span class="menu-text">{{ child.name | abpLocalization }}</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu menu-submenu-classic menu-submenu-right">
          <ul class="menu-subnav">

            <!-- begin::3 Level -->
            <ng-container *ngTemplateOutlet="forTemplate; context: { $implicit: child }"></ng-container>
            <!-- end::3 Level -->

          </ul>
        </div>
      </li>
    </ul>
  </ng-template>
</ul>
