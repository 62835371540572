import { moment } from './../../constants/moment/moment';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-date-counter',
  templateUrl: './date-counter.component.html',
  styleUrls: ['./date-counter.component.scss'],
})
export class DateCounterComponent implements OnInit, OnDestroy {
  message: string;

  @Input() date: Date = new Date();

  @Input() currentDate: Date = new Date();

  @Output() counterStoppedEvent = new EventEmitter();

  private subscription: Subscription;
  private isEmittedBefore = false;

  constructor() {}

  ngOnInit(): void {
    const endDate = moment(this.date);
    this.subscription = interval(1000).subscribe(() => {
      this.currentDate.setSeconds(this.currentDate.getSeconds() + 1);
      const currentDate = moment(this.currentDate);
      const startDateAndEndDateDifference = Math.floor(
        (endDate.valueOf() - currentDate.valueOf()) / 1000
      );
      if (startDateAndEndDateDifference > 0) {
        this.message = this.stringify(startDateAndEndDateDifference);
      } else {
        this.message = '00 : 00 : 00 : 00';
        if (!this.isEmittedBefore) {
          this.counterStoppedEvent.emit();
          this.isEmittedBefore = true;
        }
        this.subscription.unsubscribe();
      }
    });
  }

  stringify(t: number) {
    let days: number;
    let hours: number;
    let minutes: number;
    let seconds: number;
    days = Math.floor(t / 86400);
    t -= days * 86400;
    hours = Math.floor(t / 3600) % 24;
    t -= hours * 3600;
    minutes = Math.floor(t / 60) % 60;
    t -= minutes * 60;
    seconds = t % 60;

    if (days < 0) {
      days *= -1;
    }
    if (hours < 0) {
      hours *= -1;
    }
    if (minutes < 0) {
      minutes *= -1;
    }
    if (seconds < 0) {
      seconds *= -1;
    }

    return [
      days > 9 ? days : '0' + days,
      hours > 9 ? hours : '0' + hours,
      minutes > 9 ? minutes : '0' + minutes,
      seconds > 9 ? seconds : '0' + seconds,
    ].join(' : ');
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
