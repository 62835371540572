<span
  class="spinner spinner-primary p-10"
  *ngIf="!isFullScreenSpinner; else fullScreenSpinner"
></span>

<ng-template #fullScreenSpinner>
  <p-blockUI [blocked]="blockUI" [autoZIndex]="true">
    <span class="spinner spinner-primary p-10 full-screen__spinner"></span>
  </p-blockUI>
</ng-template>
