import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[appColorifyObject]'
})
export class ColorifyObjectDirective implements OnChanges{

  // tslint:disable-next-line:no-input-rename
  @Input('degree') private value: any;

  constructor(private element: ElementRef) {}

  ngOnChanges() {
    if (!this.value) {
      return;
    }
    switch (this.value.toLowerCase()) {
      case 'approved': {
        this.element.nativeElement.classList.add('text-success');
        break;
      }
      case 'accepted': {
        this.element.nativeElement.classList.add('text-success');
        break;
      }
      case 'easy': {
        this.element.nativeElement.classList.add('text-success');
        break;
      }
      case 'level_1': {
        this.element.nativeElement.classList.add('text-success');
        break;
      }
      case 'level_2': {
        this.element.nativeElement.classList.add('text-success');
        break;
      }
      case 'level_3': {
        this.element.nativeElement.classList.add('text-success');
        break;
      }
      case 'pending': {
        this.element.nativeElement.classList.add('text-warning');
        break;
      }
      case 'processing': {
        this.element.nativeElement.classList.add('text-warning');
        break;
      }
      case 'timelimitexceeded': {
        this.element.nativeElement.classList.add('text-warning');
        break;
      }
      case 'medium': {
        this.element.nativeElement.classList.add('text-warning');
        break;
      }
      case 'level_4': {
        this.element.nativeElement.classList.add('text-warning');
        break;
      }
      case 'level_5': {
        this.element.nativeElement.classList.add('text-warning');
        break;
      }
      case 'level_6': {
        this.element.nativeElement.classList.add('text-warning');
        break;
      }
      case 'level_7': {
        this.element.nativeElement.classList.add('text-warning');
        break;
      }
      case 'hard': {
        this.element.nativeElement.classList.add('text-danger');
        break;
      }
      case 'level_8': {
        this.element.nativeElement.classList.add('text-danger');
        break;
      }
      case 'level_9': {
        this.element.nativeElement.classList.add('text-danger');
        break;
      }
      case 'level_10': {
        this.element.nativeElement.classList.add('text-danger');
        break;
      }
      case 'compileerror': {
        this.element.nativeElement.classList.add('text-danger');
        break;
      }
      case 'wronganswer': {
        this.element.nativeElement.classList.add('text-danger');
        break;
      }
      case 'rejected': {
        this.element.nativeElement.classList.add('text-danger');
        break;
      }
      default: {
        this.element.nativeElement.classList.add('text-warning');
        break;
      }
    }
  }

}
