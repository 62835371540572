import { ScoreLevelTextColorDirective } from './score-level-text-color.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScoreLevelComponent } from './score-level.component';
import { ScoreLevelTitlePipe } from './score-level-title.pipe';
import { ScoreLevelBgColorDirective } from './score-level-bg-color.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    ScoreLevelComponent,
    ScoreLevelTitlePipe,
    ScoreLevelBgColorDirective,
    ScoreLevelTextColorDirective,
  ],
  exports: [ScoreLevelComponent, ScoreLevelTextColorDirective, ScoreLevelTitlePipe, ScoreLevelBgColorDirective],
})
export class ScoreLevelModule {}
