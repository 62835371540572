import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
const marked = require('marked');
const highlight = require('highlight.js');

declare var MathJax: {
  Hub: {
    Queue: (param: any[]) => void;
    Config: (param: any) => void;
  };
};

@Directive({
  selector: '[appMarkJaxForTitle]',
})
export class MarkJaxForTitleDirective implements OnChanges {
  @Input('markupValue') private value: string;
  @Input() private highlight = true;

  constructor(private element: ElementRef) {}

  ngOnChanges() {
    if (!this.value) {
      this.element.nativeElement.title = '';
      return;
    }
    if (highlight) {
      marked.setOptions({
        highlight: code => {
          return highlight.highlightAuto(code).value;
        },
      });
    }
    this.element.nativeElement.title = this.parseHtmlToText(this.value);
    MathJax.Hub.Config({
      tex2jax: {
        inlineMath: [
          ['$', '$'],
          ['\\(', '\\)'],
        ],
        processEscapes: true,
      },
    });
    MathJax.Hub.Queue(['Typeset', MathJax.Hub, this.element.nativeElement]);
  }

  private parseHtmlToText(html: string): string {
    if (html == undefined) {
      return '';
    }
    html = marked.parse(this.value);
    const temp = document.createElement('div');
    temp.innerHTML = html;
    return temp.textContent || temp.innerText || '';
  }
}
