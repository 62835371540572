import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'countryFlag',
  pure: true,
})
export class CountryFlagPipe implements PipeTransform {
  private _baseFlagsAddress = 'https://hatscripts.github.io/circle-flags/flags/';
  constructor(private translateService: TranslateService) {}

  transform(value: string): string {
    if (!value) {
      return this._baseFlagsAddress + 'xx.svg';
    }

    /**
     * Images address that comes from API, are like https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/tr.svg
     */
    const lastIndexOfOfSlash = value.lastIndexOf('/');
    const countryImage = value.substring(lastIndexOfOfSlash + 1);
    return this._baseFlagsAddress + countryImage;
  }
}
