import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CarouselModel } from '../../carousel/models/carousel.model';

@Injectable({
  providedIn: 'root'
})
export class CarouselService {

  carousels: CarouselModel[] = [];

  constructor(router: Router) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.carousels = [];
      }
    });
  }
}
