import { Injectable } from '@angular/core';
import { ClientStorageService } from '../local-storage/client-storage.service';
import { SlugModel } from '../../modules/ace-editor/models/slug-model/slug-model';
import { ProgrammingLanguageMapEditor } from '../../modules/ace-editor/constants/programming-language-map-editor/programming-language-map-editor';
import { ClientStorageKeys } from '../local-storage/client-storage.keys';
import {
  AccountClient,
  AppUserProfileClient,
  AuthClient,
  ForgotPasswordDto,
  IdentityUserDto,
  ProblemSubmissionsClient,
  RegisterDto,
  ResetPasswordDto
} from '../api/api-service';
import { AuthService, EnvironmentService } from '@abp/ng.core';
import { OAuthService, UserInfo } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private programmingLanguageSlugs: SlugModel[] = ProgrammingLanguageMapEditor.programmingLanguageSlugs;
  private userName: string;
  private profilePictureUrl: string;

  get userHasLoggedIn(): boolean {
    return this.oAuthService.hasValidAccessToken();
  }

  constructor(private clientStorageService: ClientStorageService,
              private environmentService: EnvironmentService,
              private problemSubmissionsClient: ProblemSubmissionsClient,
              private oAuthService: OAuthService,
              private authService: AuthService,
              private accountClient: AccountClient,
              private authClient: AuthClient,
              private appUserProfileClient: AppUserProfileClient,
              private router: Router) {
    oAuthService.oidc = false;
  }

  navigateToLoginPage(): void{
    this.router.navigateByUrl('/authorize/login?returnUrl=' + this.router.routerState.snapshot.url);
  }

  initLogin(): void {
    this.authService.initLogin();
  }

  login(username: string, password: string, returnUrl: string = null): Promise<void> {
    this.oAuthService.oidc = false;
    return this.oAuthService.fetchTokenUsingPasswordFlow(username, password)
      .then(() => {
         this.oAuthService.setupAutomaticSilentRefresh();
        if (returnUrl) {
          window.location.href = returnUrl;
        } else {
          window.location.href = '/';
        }
      })
      .catch(error => {
        throw new Error(error.error.error_description);
      });
  }

  register(name: string, surname: string, email: string, password: string): Promise<IdentityUserDto> {
    const environment: any = this.environmentService.getEnvironment();
    const registerDto = new RegisterDto();
    registerDto.appName = environment.application.name;
    registerDto.name = name;
    registerDto.surname = surname;
    registerDto.emailAddress = email;
    registerDto.password = password;
    return this.authClient.auth(registerDto).toPromise();
  }

  forgotPassword(email: string): Promise<void> {
    const forgotPasswordDto = new ForgotPasswordDto();
    forgotPasswordDto.email = email;
    return this.authClient.forgotPassword(forgotPasswordDto).toPromise();
  }

  resetPassword(userId: string, resetToken: string, password: string): Promise<void> {
    const resetPasswordDto = new ResetPasswordDto();
    resetPasswordDto.userId = userId;
    resetPasswordDto.resetToken = resetToken;
    resetPasswordDto.password = password;
    return this.accountClient.resetPassword(resetPasswordDto).toPromise();
  }

  logout(): void {
    this.removeUserData();
    this.authService.logout().toPromise().then(() => {
      window.location.reload();
    });
  }

  getUserProfile(): Promise<UserInfo> {
    this.oAuthService.oidc = false;
    return this.oAuthService.loadUserProfile();
  }

  getUserName(): Promise<string> {
    if (this.userName) {
      return new Promise<string>(resolve => {
        resolve(this.userName);
      });
    }
    return this.oAuthService.loadUserProfile().then((data: any) => {
      this.userName = data.preferred_username;
      return data.preferred_username;
    });
  }

  getUserProfileImage(): Promise<string> {
    if (this.profilePictureUrl) {
      return new Promise<string>(resolve => {
        resolve(this.profilePictureUrl);
      });
    }
    return this.appUserProfileClient
      .appUserProfileGet()
      .toPromise()
      .then(appUserProfile => {
        this.profilePictureUrl = appUserProfile.image;
        return appUserProfile.image;
      });
  }

  removeUserData(): void {
    for (const clientStorageKey of Object.values(ClientStorageKeys)) {
      this.clientStorageService.removeValue(clientStorageKey);
    }
  }

  getPreferProgrammingLanguageOrSetDefault(): SlugModel {
    let preferProgrammingLanguageName = this.clientStorageService.getValue(ClientStorageKeys.PreferProgrammingLanguage);
    // tslint:disable-next-line:max-line-length
    if (!preferProgrammingLanguageName || this.programmingLanguageSlugs.filter(x => x.name === preferProgrammingLanguageName).length === 0) {
      preferProgrammingLanguageName = this.programmingLanguageSlugs[0].name;
      this.clientStorageService.setValue(ClientStorageKeys.PreferProgrammingLanguage, preferProgrammingLanguageName);
    }
    return this.programmingLanguageSlugs.filter(x => x.name === preferProgrammingLanguageName)[0];
  }

  setPreferProgrammingLanguage(preferProgrammingLanguageName: string): void {
    this.clientStorageService.setValue(ClientStorageKeys.PreferProgrammingLanguage, preferProgrammingLanguageName);
  }

  async userHasCompletedTheFirstProblem(): Promise<boolean> {
    if (!this.userHasLoggedIn) {
      return false;
    }
    const environment: any = this.environmentService.getEnvironment();
    const problemSlugs = environment.getStartProblemSlugs;
    let result = false;
    const clientResult = this.clientStorageService.getValue(ClientStorageKeys.UserHasCompletedFirstProblem);
    if (clientResult) {
      return clientResult === 'true';
    }
    for (const problemSlug of problemSlugs) {
      const problemSubmissionsDto = await this.problemSubmissionsClient.byProblemSlug(problemSlug).toPromise();
      if (problemSubmissionsDto.filter(x => x.status === 'Accepted').length > 0) {
        result = true;
        break;
      }
    }
    this.clientStorageService.setValue(ClientStorageKeys.UserHasCompletedFirstProblem, (result ? 'true' : 'false'));
    return result;
  }

  skipUserForGetStartedStage(): Promise<boolean> {
    return this.appUserProfileClient.getStartedProblemStatus()
      .toPromise()
      .then((data) => {
        return data.getStartedProblemStatus !== null;
      });
  }

  userSkipSolvingFirstProblem(): void {
    this.appUserProfileClient.skipGetStartedProblemStatus().subscribe();
    this.clientStorageService.setValue(ClientStorageKeys.UserHasCompletedFirstProblem, 'true');
  }

  sendHeartBeat(): void {
    if (!this.userHasLoggedIn) {
      return;
    }
    this.appUserProfileClient.lastOnlineDate().subscribe();
  }
}
