import { Component } from '@angular/core';
import { LanguagesComponent } from '@abp/ng.theme.basic';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent extends LanguagesComponent  {
  onChangeLanguage(cultureName: string): void{
    this.onChangeLang(cultureName);
  }
}
