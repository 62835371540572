import { END_POINTS_URL } from 'src/app/shared';
import { HttpClient } from '@angular/common/http';
import { IUpdateNotification } from '../models/interface/update-notification.interface';
import { Injectable, EventEmitter } from '@angular/core';
import { NotificationTypes } from '../models/types/notification.types';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private getAllNotificationCache$: Observable<any>;
  private getAllNotificationUrl = '';
  private getUnreadNotificationsCount$: Observable<any>;
  private getUnreadNotificationsCountUrl = '';

  onChangeNotification: EventEmitter<any> = new EventEmitter<any>();

  constructor(private _http: HttpClient) {}

  getAll(
    eventType: NotificationTypes = 'None',
    skipCount = 0,
    maxResultCount = 10
  ): Observable<any> {
    //Todo: should  replace with proper API
    let URL = `${END_POINTS_URL.NOTIFICATION_API.NOTIFICATIONS}?SkipCount=${skipCount}&MaxResultCount=${maxResultCount}`;
    if (eventType != 'None') {
      URL += `&EventType=${eventType}`;
    }

    if (!this.getAllNotificationCache$ || this.getAllNotificationUrl !== URL) {
      this.getAllNotificationUrl = URL;
      this.getAllNotificationCache$ = this._http.get<any>(URL).pipe(shareReplay(1));
    }

    return this.getAllNotificationCache$;
  }

  getUnreadNotificationsCount(): Observable<any> {
    const URL = `${END_POINTS_URL.NOTIFICATION_API.NOTIFICATIONS}?isRead=false`;

    if (!this.getUnreadNotificationsCount$ || this.getUnreadNotificationsCountUrl !== URL) {
      this.getUnreadNotificationsCountUrl = URL;
      this.getUnreadNotificationsCount$ = this._http.get<any>(URL).pipe(shareReplay(1));
    }

    return this.getUnreadNotificationsCount$;
  }

  updateOne(notificationId: string, payload: IUpdateNotification) {
    const URL = `${END_POINTS_URL.NOTIFICATION_API.NOTIFICATIONS}/${notificationId}`;
    return this._http.put(URL, payload);
  }

  deleteNotificationById(notificationId: string): Observable<any> {
    const URL = `${END_POINTS_URL.NOTIFICATION_API.NOTIFICATIONS}/${notificationId}`;
    return this._http.delete(URL);
  }

  getHubUrl(): any {
    return END_POINTS_URL.NOTIFICATION_API.HUB;
  }

  readAll(): Observable<any> {
    const URL = `${END_POINTS_URL.NOTIFICATION_API.NOTIFICATIONS}/read-all`;
    return this._http.post(URL, {});
  }

  deleteAll(): Observable<any> {
    const URL = `${END_POINTS_URL.NOTIFICATION_API.NOTIFICATIONS}/all`;
    return this._http.delete(URL);
  }
}
