import { Component, Input, OnInit } from '@angular/core';
import { CarouselModel } from './models/carousel.model';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit {
  @Input() carousels: CarouselModel[] = [];

  @Input() showNavigationArrows = true;

  @Input() showNavigationIndicators = true;

  @Input() interval = 5000;

  constructor() {}

  ngOnInit(): void {}
}
