import { Component, OnInit } from '@angular/core';
import { CarouselService } from './services/carousel.service';

@Component({
  selector: 'app-carousel-service',
  templateUrl: './carousel-service.component.html',
  styleUrls: ['./carousel-service.component.scss']
})
export class CarouselServiceComponent implements OnInit {

  constructor(public carouselService: CarouselService) { }

  ngOnInit(): void {
  }

}
