import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-show-password',
  templateUrl: './show-password.component.html',
  styleUrls: ['./show-password.component.scss']
})
export class ShowPasswordComponent implements OnInit, OnChanges {

  @Input() parentDivElement: any;

  @Input() inputElement: any;

  @Input() marginTop = 25;

  show = false;

  constructor() {
    this.initElements();
  }

  ngOnInit(): void {
    this.initElements();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initElements();
  }

  initElements(): void{
    if (this.parentDivElement){
      this.parentDivElement.classList.add('d-flex');
    }
  }

  changeVisibility(): void {
    this.show = !this.show;
    if (this.inputElement) {
      this.inputElement.type = this.show ? 'text' : 'password';
    }
  }

}
