<!-- This is showing testimonial slider in home page -->
<ngb-carousel
  *ngIf="carousels?.length > 0"
  [showNavigationArrows]="showNavigationArrows"
  [showNavigationIndicators]="showNavigationIndicators"
  [interval]="interval"
>
  <ng-container *ngFor="let carousel of carousels; let i = index">
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper" [id]="'ngb-slide-' + i">
        <picture>
          <source
            [srcset]="carousel.image.svg"
            type="image/svg+xml"
            style="width: 100%"
            alt="algoleague"
            loading="lazy"
            class="embed-responsive img rounded"
            height="274"
            width="1290"
          />
          <img
            [src]="carousel.image.normal"
            style="width: 100%"
            [alt]="carousel.title ? (carousel.title | abpLocalization) : 'algoleague'"
            loading="lazy"
            class="embed-responsive img rounded"
            height="274"
            width="1290"
          />
        </picture>
      </div>
      <ng-container *ngIf="!carousel.redirectTo">
        <div class="carousel-caption">
          <h3 *ngIf="carousel.title">{{ carousel.title | abpLocalization }}</h3>
          <p *ngIf="carousel.description">{{ carousel.description | abpLocalization }}</p>
        </div>
      </ng-container>
      <ng-container *ngIf="carousel.redirectTo">
        <div class="carousel-caption cursor-pointer" [routerLink]="carousel.redirectTo">
          <h3 *ngIf="carousel.title">{{ carousel.title | abpLocalization }}</h3>
          <p *ngIf="carousel.description">{{ carousel.description | abpLocalization }}</p>
        </div>
      </ng-container>
    </ng-template>
  </ng-container>
</ngb-carousel>
