<div>
  <image-cropper
    class='max-height-400'
    cropperMinHeight='400'
    [imageFile]='file'
    [resizeToHeight]='heightProfilePicture'
    [resizeToWidth]='widthProfilePicture'
    [maintainAspectRatio]="true"
    format="png"
    (imageCropped)="imageCropped($event)"
  ></image-cropper>

  <div class='d-flex justify-content-end mt-5'>
    <button class='btn btn-default mr-5' (click)='dialogRef.close()'>{{'::Cancel' | abpLocalization}}</button>
    <button class='btn btn-primary' (click)='save()'>{{'::Save' | abpLocalization}}</button>
  </div>

</div>
