import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrimeModule, SpinnerModule } from 'src/app/shared';

import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ListComponent } from './pages/list/list.component';
import { LocalizationModule } from '@abp/ng.core';
import { MatDialogModule } from '@angular/material/dialog';
import { NgModule } from '@angular/core';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NotificationComponent } from './notification.component';
import { NotificationIconModule } from 'src/app/shared/components/notification-icon/notification-icon.module';
import { NotificationRoutingModule } from './notification-routing.module';
import { SpilitTextIntoSpecificLengthPipe } from './utils/pipes/spilit-text-into-specific-length.pipe';

@NgModule({
  declarations: [NotificationComponent, ListComponent, SpilitTextIntoSpecificLengthPipe],
  imports: [
    CommonModule,
    NotificationRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    LocalizationModule,
    NgbPaginationModule,
    PrimeModule,
    MatDialogModule,
    SpinnerModule,
    NotificationIconModule,
  ],
})
export class NotificationModule {}
