export class GeneralUtils {
  static copyContentToClipboard(txt) {
    const fakeElement = document.createElement('textarea');
    fakeElement.style.position = 'fixed';
    fakeElement.style.left = '0';
    fakeElement.style.top = '0';
    fakeElement.style.opacity = '0';
    fakeElement.value = txt;
    document.body.appendChild(fakeElement);
    fakeElement.focus();
    fakeElement.select();
    document.execCommand('copy');
    document.body.removeChild(fakeElement);
  }

  static newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
}
