import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Delay } from '../../utilities/Delay';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {

  _PAGE = 1;
  _PAGESIZE = 10;
  _TOTALCOUNT = 0;
  _TOTALPAGEARRAY: any[] = [];

  @Input() set page(value: number) {
    this._PAGE = value;
  }
  @Input() set pageSize(value: number) {
    this._PAGESIZE = value;
    this.initTotalPageArray();
  }
  @Input() set totalCount(value: number) {
    this._TOTALCOUNT = value;
    this.initTotalPageArray();
  }

  @Output() pageChanged = new EventEmitter<number>();

  async initTotalPageArray(): Promise<any>{
    await Delay();
    if (this._TOTALCOUNT < 1 || this._PAGESIZE < 1){
      this._TOTALPAGEARRAY = [];
      return;
    }
    const arrayLength = Math.ceil(this._TOTALCOUNT / this._PAGESIZE);
    this._TOTALPAGEARRAY = [].constructor(arrayLength);
  }

  constructor() { }

  ngOnInit(): void {
  }

  pageChange(page: number): void{
    this._PAGE = page;
    this.pageChanged.emit(page);
  }

}
