import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../../../../../core';
import { UserService } from '../../../../../../../../core/services/user/user.service';

@Component({
  selector: 'app-user-offcanvas',
  templateUrl: './user-offcanvas.component.html',
  styleUrls: ['./user-offcanvas.component.scss'],
})
export class UserOffcanvasComponent implements OnInit {
  extrasUserOffcanvasDirection = 'offcanvas-right';
  userInfo: any;

  // tslint:disable-next-line:max-line-length
  constructor(private layout: LayoutService, public userService: UserService) {
  }

  ngOnInit(): void {
    this.userService.getUserProfile().then((userInfo) => {
      this.userInfo = userInfo;
    });
    this.extrasUserOffcanvasDirection = `offcanvas-${this.layout.getProp(
      'extras.user.offcanvas.direction'
    )}`;
  }
}
