import { LanguageSlugModel } from '../../models/slug-model/slug-model';

export class ProgrammingLanguageMapEditor {
  static programmingLanguageSlugs: LanguageSlugModel[] = [
    {
      name: 'TypeScript',
      slug: 'typescript',
      extensions: ['.ts', '.tsx'],
      template: '',
    },
    {
      name: 'Ruby',
      slug: 'ruby',
      extensions: [
        '.rb',
        '.builder',
        '.fcgi',
        '.gemspec',
        '.god',
        '.irbrc',
        '.jbuilder',
        '.mspec',
        '.pluginspec',
        '.podspec',
        '.rabl',
        '.rake',
        '.rbuild',
        '.rbw',
        '.rbx',
        '.ru',
        '.ruby',
        '.thor',
        '.watchr',
      ],
      template: '',
    },
    {
      name: 'JavaScript (Node.js)',
      slug: 'javascript',
      extensions: [
        '.js',
        '._js',
        '.bones',
        '.es',
        '.es6',
        '.frag',
        '.gs',
        '.jake',
        '.jsb',
        '.jscad',
        '.jsfl',
        '.jsm',
        '.jss',
        '.njs',
        '.pac',
        '.sjs',
        '.ssjs',
        '.sublime-build',
        '.sublime-commands',
        '.sublime-completions',
        '.sublime-keymap',
        '.sublime-macro',
        '.sublime-menu',
        '.sublime-mousemap',
        '.sublime-project',
        '.sublime-settings',
        '.sublime-theme',
        '.sublime-workspace',
        '.sublime_metrics',
        '.sublime_session',
        '.xsjs',
        '.xsjslib',
      ],
      template:
        '//Debug or not to debug… That is the question.\n' +
        '//inzva community built algoleague for every algorithm enthusiast hungry for self-improvement and friendly competition. Have fun and good luck!\n' +
        '\n' +
        'process.stdin.resume();\n' +
        "process.stdin.setEncoding('utf-8');\n" +
        '\n' +
        'function main() {\n' +
        '// write your code here\n' +
        '\n' +
        '}\n',
    },
    {
      name: 'Python 3',
      slug: 'python',
      extensions: [
        '.py',
        '.bzl',
        '.cgi',
        '.fcgi',
        '.gyp',
        '.lmi',
        '.pyde',
        '.pyp',
        '.pyt',
        '.pyw',
        '.rpy',
        '.tac',
        '.wsgi',
        '.xpy',
      ],
      template:
        '# Practice makes it perfect\n' +
        '# inzva community built algoleague for every algorithm enthusiast hungry \n' +
        '# for self-improvement and friendly competition. Have fun and good luck!',
    },
    {
      name: 'C',
      slug: 'c_cpp',
      extensions: ['.c', '.cats', '.h', '.idc', '.w'],
      template:
        '//Even our problem setter cannot nail this everytime. No giving up!\n' +
        '//inzva community built algoleague for every algorithm enthusiast hungry for self-improvement and friendly competition. Have fun and good luck!\n' +
        '\n' +
        '#include <stdio.h>\n' +
        '\n' +
        'int main() {\n' +
        '\t// write your code here\n' +
        '\n' +
        '\treturn 0;\n' +
        '}\n' +
        '\n',
    },
    {
      name: 'Swift',
      slug: 'swift',
      extensions: ['.swift'],
      template: '',
    },
    {
      name: 'Java 13.0.1',
      slug: 'java',
      extensions: ['.java'],
      template:
        '//Brace your keyboard\n' +
        '//inzva community built algoleague for every algorithm enthusiast hungry for self-improvement and friendly competition. Have fun and good luck!\n' +
        '\n' +
        'import java.util.*;\n' +
        'import java.lang.*;\n' +
        'import java.io.*;\n' +
        '\n' +
        'class Main {\n' +
        '    public static void main (String[] args) {\n' +
        '\n' +
        '    }\n' +
        '}\n',
    },
    {
      name: 'C++ (Clang 7.0.1)',
      slug: 'c_cpp',
      extensions: [
        '.cpp',
        '.c++',
        '.cc',
        '.cp',
        '.cxx',
        '.h',
        '.h++',
        '.hh',
        '.hpp',
        '.hxx',
        '.inc',
        '.inl',
        '.ipp',
        '.tcc',
        '.tpp',
      ],
      template:
        '//Brace your keyboard\n' +
        '//inzva community built algoleague for every algorithm enthusiast hungry for self-improvement and friendly competition. Have fun and good luck!\n' +
        '\n' +
        '#include <bits/stdc++.h>\n' +
        '\n' +
        'using namespace std;\n' +
        '\n' +
        'int main() {\n' +
        '// write your code here\n' +
        '\n' +
        '\treturn 0;\n' +
        '}\n',
    },
    {
      name: 'C++ (GCC 9.2.0)',
      slug: 'c_cpp',
      extensions: [
        '.cpp',
        '.c++',
        '.cc',
        '.cp',
        '.cxx',
        '.h',
        '.h++',
        '.hh',
        '.hpp',
        '.hxx',
        '.inc',
        '.inl',
        '.ipp',
        '.tcc',
        '.tpp',
      ],
      template:
        '//Brace your keyboard\n' +
        '//inzva community built algoleague for every algorithm enthusiast hungry for self-improvement and friendly competition. Have fun and good luck!\n' +
        '\n' +
        '#include <bits/stdc++.h>\n' +
        '\n' +
        'using namespace std;\n' +
        '\n' +
        'int main() {\n' +
        '// write your code here\n' +
        '\n' +
        '\treturn 0;\n' +
        '}\n',
    },
    {
      name: 'C++',
      slug: 'c_cpp',
      extensions: [
        '.cpp',
        '.c++',
        '.cc',
        '.cp',
        '.cxx',
        '.h',
        '.h++',
        '.hh',
        '.hpp',
        '.hxx',
        '.inc',
        '.inl',
        '.ipp',
        '.tcc',
        '.tpp',
      ],
      template:
        '//Brace your keyboard\n' +
        '//inzva community built algoleague for every algorithm enthusiast hungry for self-improvement and friendly competition. Have fun and good luck!\n' +
        '\n' +
        '#include <bits/stdc++.h>\n' +
        '\n' +
        'using namespace std;\n' +
        '\n' +
        'int main() {\n' +
        '// write your code here\n' +
        '\n' +
        '\treturn 0;\n' +
        '}\n',
    },
    {
      name: 'Python 2',
      slug: 'python',
      extensions: [
        '.py',
        '.bzl',
        '.cgi',
        '.fcgi',
        '.gyp',
        '.lmi',
        '.pyde',
        '.pyp',
        '.pyt',
        '.pyw',
        '.rpy',
        '.tac',
        '.wsgi',
        '.xpy',
      ],
      template:
        '# Code your way to the top\n' +
        '# inzva community built algoleague for every algorithm enthusiast hungry \n' +
        '# for self-improvement and friendly competition. Have fun and good luck!',
    },
    {
      name: 'C#',
      slug: 'csharp',
      extensions: ['.cs', '.cake', '.cshtml', '.csx'],
      template:
        '//Practice makes it perfect\n' +
        '//inzva community built algoleague for every algorithm enthusiast hungry for self-improvement and friendly competition. Have fun and good luck!\n' +
        '\n' +
        'using System;\n' +
        'using System.Collections.Generic;\n' +
        'using System.IO;\n' +
        '\n' +
        'class Solution {\n' +
        '\tstatic void Main(String[] args) {\n' +
        '\n' +
        '\t}\n' +
        '}\n',
    },
    {
      name: 'Python 2.7 (PyPy 7.3.12)',
      slug: 'python',
      extensions: [
        '.py',
        '.bzl',
        '.cgi',
        '.fcgi',
        '.gyp',
        '.lmi',
        '.pyde',
        '.pyp',
        '.pyt',
        '.pyw',
        '.rpy',
        '.tac',
        '.wsgi',
        '.xpy',
      ],
      template:
        '# Practice makes it perfect\n' +
        '# inzva community built algoleague for every algorithm enthusiast hungry \n' +
        '# for self-improvement and friendly competition. Have fun and good luck!',
    },
    {
      name: 'Python 3.10 (PyPy 7.3.12)',
      slug: 'python',
      extensions: [
        '.py',
        '.bzl',
        '.cgi',
        '.fcgi',
        '.gyp',
        '.lmi',
        '.pyde',
        '.pyp',
        '.pyt',
        '.pyw',
        '.rpy',
        '.tac',
        '.wsgi',
        '.xpy',
      ],
      template:
        '# Practice makes it perfect\n' +
        '# inzva community built algoleague for every algorithm enthusiast hungry \n' +
        '# for self-improvement and friendly competition. Have fun and good luck!',
    },
    {
      name: 'Python 3.9 (PyPy 7.3.12)',
      slug: 'python',
      extensions: [
        '.py',
        '.bzl',
        '.cgi',
        '.fcgi',
        '.gyp',
        '.lmi',
        '.pyde',
        '.pyp',
        '.pyt',
        '.pyw',
        '.rpy',
        '.tac',
        '.wsgi',
        '.xpy',
      ],
      template:
        '# Practice makes it perfect\n' +
        '# inzva community built algoleague for every algorithm enthusiast hungry \n' +
        '# for self-improvement and friendly competition. Have fun and good luck!',
    },
  ];

  static getSlugByName(name: string): string {
    const programmingLanguageSlug = this.programmingLanguageSlugs.filter(x => x.name === name)[0];
    return programmingLanguageSlug?.slug ?? name;
  }

  static getTemplateByName(name: string): string {
    const programmingLanguageSlug = this.programmingLanguageSlugs.filter(x => x.name === name)[0];
    return programmingLanguageSlug?.template ?? '';
  }

  static getNameByExtension(extension: string): string {
    let result: string;
    for (const item of this.programmingLanguageSlugs) {
      if (!item.extensions.includes(extension)) {
        continue;
      } else {
        result = item.name;
        break;
      }
    }
    return result;
  }
}
