import { environment } from 'src/environments/environment';

export const END_POINTS_URL = {
  CMS_API: {
    CMS_PUBLIC_COMMENTS: `${environment.apis.comments.url}/api/cms-kit-public/comments`,
    CMS_CUSTOM_COMMENTS: `${environment.apis.comments.url}/api/app/comments`,
    VOTES: `${environment.apis.comments.url}/api/app/votes`,
  },
  NOTIFICATION_API: {
    NOTIFICATIONS: `${environment.apis.notifications.url}/api/app/notifications`,
    HUB: `${environment.apis.notifications.url}/hub/notification`,
  },
  CONTEST_API: {
    CONTESTS: `${environment.apis.default.url}/api/app/contests`,
  },
  TRAINING_API: {
    TRAINING: `${environment.apis.default.url}/api/app/training`,
  },
};
