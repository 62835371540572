import { RouterModule, Routes } from '@angular/router';

import { AccountLayoutComponent } from './shared/templates/_metronic/layouts/account-layout/account-layout.component';
import { ApplicationLayoutComponent } from './shared/templates/_metronic/layouts/application-layout/application-layout.component';
import { ApplicationWideLayoutComponent } from './shared/templates/_metronic/layouts/application-wide-layout/application-wide-layout.component';
import { AuthGuardService } from './core/services/auth-guard/auth-guard.service';
import { NgModule } from '@angular/core';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: ApplicationWideLayoutComponent,
    loadChildren: () => import('./features/modules/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'identity',
    canActivate: [AuthGuardService],
    loadChildren: () => import('@abp/ng.identity').then(m => m.IdentityModule.forLazy()),
  },
  {
    path: 'tenant-management',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('@abp/ng.tenant-management').then(m => m.TenantManagementModule.forLazy()),
  },
  {
    path: 'setting-management',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('@abp/ng.setting-management').then(m => m.SettingManagementModule.forLazy()),
  },
  {
    path: 'authorize',
    component: AccountLayoutComponent,
    loadChildren: () =>
      import('./features/modules/authorize/authorize.module').then(m => m.AuthorizeModule),
  },
  {
    path: 'get-start',
    component: AccountLayoutComponent,
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./features/modules/get-start/get-start.module').then(m => m.GetStartModule),
  },
  {
    path: 'problem',
    component: ApplicationLayoutComponent,
    loadChildren: () =>
      import('./features/modules/problem/problem.module').then(m => m.ProblemModule),
  },
  {
    path: 'contest',
    component: ApplicationLayoutComponent,
    loadChildren: () =>
      import('./features/modules/contest/contest.module').then(m => m.ContestModule),
  },
  {
    path: 'profile',
    component: ApplicationLayoutComponent,
    loadChildren: () =>
      import('./features/modules/profile/profile.module').then(m => m.ProfileModule),
  },
  {
    path: 'team',
    component: ApplicationLayoutComponent,
    loadChildren: () => import('./features/modules/team/team.module').then(m => m.TeamModule),
  },
  {
    path: 'page',
    component: ApplicationLayoutComponent,
    loadChildren: () => import('./features/modules/page/page.module').then(m => m.PageModule),
  },
  {
    path: 'notifications',
    canActivate: [AuthGuardService],
    component: ApplicationLayoutComponent,
    loadChildren: () =>
      import('./features/modules/notification/notification.module').then(m => m.NotificationModule),
  },
  {
    path: 'leaderboard',
    component: ApplicationLayoutComponent,
    loadChildren: () =>
      import('./features/modules/leaderboard/leaderboard.module').then(m => m.LeaderboardModule),
  },
  {
    path: 'training',
    component: ApplicationLayoutComponent,
    loadChildren: () =>
      import('./features/modules/training/training.module').then(m => m.TrainingModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
