<button
  mat-icon-button
  [matMenuTriggerFor]="userSearch"
  class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pulse pulse-primary bg-transparent-h"
>
  <i class="fa fa-search"></i>
</button>

<mat-menu #userSearch="matMenu">
  <div class="user-search-box">
    <div class="user-search-input">
      <i class="fa fa-search"></i>
      <input
        class="form-control"
        style="border: none !important"
        (click)="$event.stopPropagation()"
        (keypress)="searchUsers($event)"
        placeholder="Search..."
      />
    </div>
    <div class="navi navi-spacer-x-0 pt-5" *ngIf="foundUsers && foundUsers.length > 0">
      <div class="mb-5">
        <div class="d-flex flex-stack fw-semibold mb-4">
          <span class="text-muted fs-6 mr-2">Recently Searched:</span>
        </div>
        <div class="scroll-y mh-200px mh-lg-325px search-result__section">
          <div class="d-flex align-items-start mb-5" *ngFor="let user of foundUsers">
            <div class="symbol symbol-40px mr-4" *ngIf="!user.image; else userImage">
              <span class="symbol-label bg-light">
                <i class="ki-duotone ki-laptop fs-2 text-primary"
                  ><span class="path1"></span><span class="path2"></span
                ></i>
              </span>
            </div>
            <ng-template #userImage>
              <img [src]="user.image" class="h-50px w-50px mr-4 user-image" />
            </ng-template>
            <div class="d-flex flex-column">
              <a
                [routerLink]="'/profile/' + user.userName + '/overview'"
                class="fs-6 text-gray-800 text-hover-primary fw-semibold"
                >{{ user.userName }}</a
              >
              <span class="fs-7 text-muted fw-semibold">{{ user.name }} {{ user.surname }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center" *ngIf="foundUsers && foundUsers.length === 0">
        <i class="bi bi-info-circle icon-5x"></i>
        <span class="d-block text-dark-50">{{ '::NoRecordFound' | abpLocalization }}</span>
      </div>
    </div>
  </div>
</mat-menu>
