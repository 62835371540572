import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-score-level',
  templateUrl: './score-level.component.html',
  styleUrls: ['./score-level.component.scss'],
})
export class ScoreLevelComponent  {
  @Input()
  publicScore: number = 0;

  constructor() {}
}
