<!--begin::Main-->
<ng-container *ngIf="selfLayout !== 'blank'; else blankLayout">

  <div class='d-flex flex-column flex-root'>
    <!--begin::Page-->
    <div class='d-flex flex-row flex-column-fluid page'>
      <!--begin::Wrapper-->
      <div class='d-flex flex-column flex-row-fluid' id='kt_wrapper'>

        <!-- begin:: Content -->
        <div
          class='d-flex flex-column flex-root'
          [ngClass]='contentClasses'
        >

          <!-- begin:: Content Body -->
          <ng-container *ngIf='contentExtended'>
            <router-outlet></router-outlet>
          </ng-container>

          <ng-container *ngIf='!contentExtended'>
            <!--begin::Entry-->
            <div class='d-flex flex-column flex-root'>

              <div class='login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white'
                   id='kt_login'>
                <!--begin::Aside-->
                <div class='login-aside d-flex flex-column flex-row-auto' appRandomBackgroundImage
                     style='height: 100vh; position:relative; background: linear-gradient(19.8deg, #181C32 0%, rgba(0, 0, 0, 0) 100%); background-size: cover'>

                  <div routerLink='/' style='margin:1.5rem 3.5rem;width:182px;height:32px;z-index: 99;background-image: url("/assets/media/svg/logos/logo-white.svg"); background-size: contain; background-repeat: no-repeat; background-position: center; cursor: pointer'></div>
                  <div class="mask" style="background: url(/assets/media/logos/mask-image.png); background-size: cover; width: 100%; height: 100%; position: absolute"></div>

                  <!--begin::Aside Top-->
                  <div class='d-flex flex-column-auto flex-column pt-lg-40 p-15 w-100'
                       style='position: absolute; bottom: 0rem'>
                    <!--begin::Aside title-->
                    <h3 class='justify-content-end font-weight-bolder display-4 text-white'>
                      {{layout.welcomeTitle | abpLocalization}}
                    </h3>
                    <h4 class='justify-content-end font-weight-light font-size-h1 text-white'>
                      <br />{{layout.welcomeDescription | abpLocalization}}
                    </h4>
                    <h5 class='justify-content-end font-size-sm font-weight-lighter text-white mt-10 mb-0'>{{'::Photo By' | abpLocalization}} <b>{{layout.imageCreator}}</b> {{'::on' | abpLocalization}} <b>Unsplash</b></h5>
                    <!--end::Aside title-->
                  </div>
                  <!--end::Aside Top-->
                </div>
                <!--begin::Aside-->

                <!--begin::Content-->

                <div
                  class='login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden mx-auto'>

                  <router-outlet></router-outlet>

                  <ng-container *ngIf='footerDisplay'>
                    <!--begin::Footer-->
                    <app-footer
                      class='footer bg-white py-4 d-flex flex-lg-column w-100'
                      id='kt_footer'
                      [ngClass]='footerCSSClasses'
                    ></app-footer>
                    <!--end::Footer-->
                  </ng-container>

                </div>

                <!--end::Content-->

              </div>
            </div>
            <!--end::Entry-->
          </ng-container>
          <!-- end:: Content Body -->
        </div>
        <!-- end:: Content -->
      </div>
      <!--end::Wrapper-->
    </div>
    <!-- end:: Body -->
    <!--end::Page-->

    <ng-container *ngIf='extrasScrollTopDisplay'>
      <app-scroll-top></app-scroll-top>
    </ng-container>
    <!--end::Offcanvases-->
  </div>
</ng-container>

<ng-template #blankLayout>
  <div class='d-flex flex-column flex-root'>
    <router-outlet></router-outlet>
  </div>
</ng-template>
<app-scripts-init></app-scripts-init>
<!--end::Main-->
