import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'digitalClock',
})
export class DigitalClockPipe implements PipeTransform {
  transform(value: number, showSeconds = false): string {
    const hour = Math.floor(value / 3600);
    const minute = Math.floor((value % 3600) / 60);
    const second = Math.floor((value % 3600) % 60);

    const hDisplay = (hour + '').length == 1 ? '0' + hour : hour + '';
    const mDisplay = (minute + '').length == 1 ? '0' + minute : minute + '';
    const sDisplay = (second + '').length == 1 ? '0' + second : second + '';
    return showSeconds ? `${hDisplay} : ${mDisplay} : ${sDisplay}` : `${hDisplay} : ${mDisplay}`;
  }
}
