import * as CoreModuleAbp from '@abp/ng.core';

import { NgbDropdownModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';

import { AccountLayoutComponent } from './shared/templates/_metronic/layouts/account-layout/account-layout.component';
import { AppComponent } from './features/components/app.component';
import { AppRoutingModule } from './app-routing.module';
import { ApplicationLayoutComponent } from './shared/templates/_metronic/layouts/application-layout/application-layout.component';
import { ApplicationWideLayoutComponent } from './shared/templates/_metronic/layouts/application-wide-layout/application-wide-layout.component';
import { AsideComponent } from './shared/templates/_metronic/components/aside/aside.component';
import { AsideDynamicComponent } from './shared/templates/_metronic/components/aside-dynamic/aside-dynamic.component';
import { AuthGuardService } from './core/services/auth-guard/auth-guard.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ComponentsModule } from './core/components/components.module';
import { CoreModule } from './shared/templates/_metronic/core';
import { DirectivesModule } from './core/directives/directives.module';
import { EmptyLayoutComponent } from './shared/templates/_metronic/layouts/empty-layout/empty-layout.component';
import { ExtrasModule } from './shared/templates/_metronic/partials/layout/extras/extras.module';
import { FooterComponent } from './shared/templates/_metronic/components/footer/footer.component';
import { FooterLargeComponent } from './shared/templates/_metronic/components/footer-large/footer-large.component';
import { HeaderComponent } from './shared/templates/_metronic/components/header/header.component';
import { HeaderMenuComponent } from './shared/templates/_metronic/components/header/header-menu/header-menu.component';
import { HeaderMenuDynamicComponent } from './shared/templates/_metronic/components/header/header-menu-dynamic/header-menu-dynamic.component';
import { HeaderMobileComponent } from './shared/templates/_metronic/components/header-mobile/header-mobile.component';
import { IdentityConfigModule } from '@abp/ng.identity/config';
import { InlineSVGModule } from 'ng-inline-svg';
import { LanguageSelectorComponent } from './shared/templates/_metronic/components/topbar/language-selector/language-selector.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { NotificationIconModule } from './shared/components/notification-icon/notification-icon.module';
import { PipeModule } from './core/pipes/pipe.module';
import { ScriptsInitComponent } from './shared/templates/_metronic/components/init/scipts-init/scripts-init.component';
import { SettingManagementConfigModule } from '@abp/ng.setting-management/config';
import { SplashScreenModule } from './shared/templates/_metronic/partials/layout/splash-screen/splash-screen.module';
import { SubheaderModule } from './shared/templates/_metronic/partials/layout/subheader/subheader.module';
import { TenantManagementConfigModule } from '@abp/ng.tenant-management/config';
import { ThemeBasicModule } from '@abp/ng.theme.basic';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { TopbarComponent } from './shared/templates/_metronic/components/topbar/topbar.component';
import { TranslateModule } from '@ngx-translate/core';
import { UnReadNotificationsComponent } from './shared/templates/_metronic/components/topbar/un-read-notifications/un-read-notifications.component';
import { environment } from '../environments/environment';
import { registerLocale } from '@abp/ng.core/locale';
import {
  HTTP_ERROR_INTERCEPTOR_INITIALIZER_PROVIDER,
  MAINTENANCE_INTERCEPTOR_INITIALIZER_PROVIDER,
} from './core/interceptors';
import { MaintenanceComponent } from './features/components/maintenance.component';
import { UserSearchComponent } from './shared/templates/_metronic/components/topbar/user-search/user-search.component';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModuleAbp.CoreModule.forRoot({
      environment,
      registerLocaleFn: registerLocale(),
      skipGetAppConfiguration: false,
    }),
    ThemeSharedModule.forRoot(),
    IdentityConfigModule.forRoot(),
    TenantManagementConfigModule.forRoot(),
    SettingManagementConfigModule.forRoot(),
    NgxsModule.forRoot(),
    ThemeBasicModule.forRoot(),
    TranslateModule.forRoot(),
    SplashScreenModule,
    SubheaderModule,
    ExtrasModule,
    NgbProgressbarModule,
    InlineSVGModule,
    CoreModule,
    MatMenuModule,
    MatIconModule,
    NgbDropdownModule,
    DirectivesModule,
    PipeModule,
    ComponentsModule,
    NotificationIconModule,
  ],
  // tslint:disable-next-line:max-line-length
  declarations: [
    AppComponent,
    ApplicationLayoutComponent,
    HeaderMobileComponent,
    AsideComponent,
    AsideDynamicComponent,
    HeaderComponent,
    FooterComponent,
    ScriptsInitComponent,
    HeaderMenuComponent,
    HeaderMenuDynamicComponent,
    TopbarComponent,
    LanguageSelectorComponent,
    AccountLayoutComponent,
    EmptyLayoutComponent,
    FooterLargeComponent,
    ApplicationWideLayoutComponent,
    UnReadNotificationsComponent,
    MaintenanceComponent,
    UserSearchComponent
  ],
  providers: [
    AuthGuardService,
    MAINTENANCE_INTERCEPTOR_INITIALIZER_PROVIDER,
    HTTP_ERROR_INTERCEPTOR_INITIALIZER_PROVIDER,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
