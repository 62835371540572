import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowPasswordComponent } from './show-password/show-password.component';
import { DateCounterComponent } from './date-counter/date-counter.component';
import { CarouselServiceComponent } from './carousel-service/carousel-service.component';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { LocalizationModule } from '@abp/ng.core';
import { RouterModule } from '@angular/router';
import { ImageCropperComponent } from './image-cropper/image-cropper.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CarouselComponent } from './carousel/carousel.component';
import { PaginatorComponent } from './paginator/paginator.component';

@NgModule({
  declarations: [ShowPasswordComponent, DateCounterComponent, CarouselServiceComponent, ImageCropperComponent, CarouselComponent, PaginatorComponent],
  imports: [
    CommonModule,
    NgbCarouselModule,
    LocalizationModule,
    RouterModule,
    ImageCropperModule
  ],
  exports: [
    ShowPasswordComponent,
    DateCounterComponent,
    CarouselServiceComponent,
    CarouselComponent,
    PaginatorComponent
  ]
})
export class ComponentsModule { }
