<ul class='pagination'>
  <li class='page-item ng-star-inserted cursor-pointer' [ngClass]='{"disabled": _PAGE < 2, "cursor-pointer": _PAGE > 1}'>
    <a aria-label='First'  (click)='pageChange(1)' class='page-link' tabindex='-1' aria-disabled='true'>
      <span aria-hidden='true' class='ng-star-inserted'>««</span>
    </a>
  </li>
  <li class='page-item ng-star-inserted cursor-pointer' [ngClass]='{"disabled": _PAGE < 2, "cursor-pointer": _PAGE > 1}'>
    <a aria-label='Previous'  (click)='pageChange(_PAGE - 1)' class='page-link' tabindex='-1' aria-disabled='true'>
      <span aria-hidden='true' class='ng-star-inserted'>«</span></a>
  </li>
  <li class='page-item ng-star-inserted cursor-pointer' *ngFor='let n of _TOTALPAGEARRAY let i = index' [ngClass]='{"active": _PAGE === i + 1}'>
    <a class='page-link ng-star-inserted' (click)='pageChange(i + 1)'> {{i + 1}}</a>
  </li>
  <li class='page-item ng-star-inserted cursor-pointer' [ngClass]='{"disabled": _PAGE >= _TOTALPAGEARRAY.length, "cursor-pointer": _PAGE < _TOTALPAGEARRAY.length}'>
    <a aria-label='Next' (click)='pageChange(_PAGE + 1)' class='page-link'>
      <span aria-hidden='true' class='ng-star-inserted'>»</span>
    </a>
  </li>
  <li class='page-item ng-star-inserted' [ngClass]='{"disabled": _PAGE >= _TOTALPAGEARRAY.length, "cursor-pointer": _PAGE < _TOTALPAGEARRAY.length}'>
    <a aria-label='Last' (click)='pageChange(_TOTALPAGEARRAY.length)' class='page-link'>
      <span aria-hidden='true' class='ng-star-inserted'>»»</span>
    </a>
  </li>
</ul>
