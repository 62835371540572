import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { retry } from 'rxjs/internal/operators/retry';
import { Router } from '@angular/router';
import { ContentProjectionService, PROJECTION_STRATEGY } from '@abp/ng.core';
import { MaintenanceComponent } from 'src/app/features/components/maintenance.component';
import { throwError } from 'rxjs';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private injector: Injector, private router: Router) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry(0),
      catchError((error: HttpErrorResponse) => {
        if (
          error.hasOwnProperty('error') &&
          error.error.hasOwnProperty('error') &&
          error.error.error.code == 'GeneralMaintenanceModeActive'
        ) {
          const contentProjection = this.injector.get(ContentProjectionService);
          contentProjection.projectContent(PROJECTION_STRATEGY.AppendComponentToBody(MaintenanceComponent));
          return;
        }
        return throwError(error);
      })
    );
  }
}

export const HTTP_ERROR_INTERCEPTOR_INITIALIZER_PROVIDER = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true,
  },
];
