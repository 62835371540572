import * as signalR from '@aspnet/signalr';

import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';

import { END_POINTS_URL } from 'src/app/shared';
import { NotificationService } from 'src/app/features/modules/notification';
import { OAuthService } from 'angular-oauth2-oidc';
import { SubSink } from 'subsink';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-un-read-notifications',
  templateUrl: './un-read-notifications.component.html',
  styleUrls: ['./un-read-notifications.component.scss'],
})
export class UnReadNotificationsComponent implements OnInit, OnDestroy {
  public notifications: any[] = [];
  public unReadNotificationsCount = 0;

  private _subs = new SubSink();
  private hubConnection: signalR.HubConnection;

  constructor(
    private _notificationService: NotificationService,
    private oAuthService: OAuthService,
    private _cdr: ChangeDetectorRef
  ) {
    this._subs.sink = this._notificationService.onChangeNotification.subscribe(() => {
      this._fetchRequiredData();
    });

    this.connectHub();
  }

  ngOnInit(): void {
    this._fetchRequiredData();
  }

  public handleVisitNotification(notification: any): void {
    const URL = notification.notificationParameters.find(x => x.key.toLowerCase() == 'url');
    if (!notification.isRead) {
      this._subs.sink = this._notificationService
        .updateOne(notification.id, {
          isRead: true,
        })
        .pipe(
          finalize(() => {
            this._cdr.detectChanges();
          })
        )
        .subscribe(result => {
          const idx = this.notifications.findIndex(x => x.id == notification.id);
          this.notifications[idx].isRead = true;
          this._notificationService.onChangeNotification.emit();
          if (URL) {
            window.location.href = URL.value;
          }
        });
    } else if (notification.isRead && URL) {
      window.location.href = URL.value;
    }
  }

  private _fetchRequiredData() {
    this._subs.sink = this._notificationService.getAll('None', 0, 5).subscribe(result => {
      const notifications = result.items;
      notifications.forEach(item => {
        const contestName = item.notificationParameters.find(x => x.key == 'contestName')?.value;
        if (item.notificationType.eventType === 'ContestMaintenanceMode') {
          const isActive = item.notificationParameters.find(x => x.key == 'isActive')?.value;
          const userName = item.notificationParameters.find(x => x.key == 'userName')?.value;
          const activeMessage = item.notificationParameters.find(
            x => x.key == 'activeMessage'
          )?.value;
          item.notificationType.message = item.notificationType.message
            .replace('{isActive}', isActive)
            .replace('{contestName}', contestName)
            .replace('{userName}', userName)
            .replace('{activeMessage}', activeMessage);
        }
        if (item.notificationType.eventType === 'ContestSignUpStatusChange') {
          const status = item.notificationParameters.find(x => x.key == 'status')?.value;
          item.notificationType.message = item.notificationType.message
            .replace('{status}', status)
            .replace('{contestName}', contestName);
        }
        if (item.notificationType.eventType === 'GLeaderboardEvaluation') {
          const score = item.notificationParameters.find(x => x.key == 'score')?.value;
          const title = item.notificationParameters.find(x => x.key == 'title')?.value;
          item.notificationType.message = item.notificationType.message
            .replace('{score}', score)
            .replace('{title}', title)
            .replace('{contestName}', contestName);
        }
        if (item.notificationType.eventType === 'ProblemAssignRole') {
          const role = item.notificationParameters.find(x => x.key == 'role')?.value;
          const problemName = item.notificationParameters.find(x => x.key == 'problemName')?.value;
          const userName = item.notificationParameters.find(x => x.key == 'userName')?.value;
          item.notificationType.message = item.notificationType.message
            .replace('{role}', role)
            .replace('{userName}', userName)
            .replace('{problemName}', problemName);
        } else {
          const userName = item.notificationParameters.find(x => x.key == 'userName')?.value;
          item.notificationType.message = item.notificationType.message
            .replace('{userName}', userName)
            .replace('{contestName}', contestName);
        }
        const URL = item.notificationParameters.find(x => x.key == 'url');
        item.url = URL == undefined ? '' : URL.value;
      });
      this.notifications = notifications;
      this._getUnreadNotificationCount();
    });
  }

  private _getUnreadNotificationCount() {
    this._subs.sink = this._notificationService.getUnreadNotificationsCount().subscribe(data => {
      this.unReadNotificationsCount = data.totalCount;
    });
  }

  private connectHub() {
    const hubUrl = this._notificationService.getHubUrl();
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(hubUrl, { accessTokenFactory: () => this.oAuthService.getAccessToken() })
      .build();

    this.hubConnection.start().catch(err => console.error(err.toString()));
    this.hubConnection.on('Notification', notification => {
      this._notificationService.onChangeNotification.emit();
    });
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
