import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  @Input('message')
  public message = '';

  @Input('alertType')
  public alertType: 'danger' | 'primary' | 'warning' | 'success' = 'warning';

  constructor() {}

  ngOnInit(): void {}
}
