import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

@Injectable()
export class MaintenanceInterceptor implements HttpInterceptor {
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const MAINTENANCE_HEADER = { ClientProvideType: 'UserClient' };
    return next.handle(httpRequest.clone({ setHeaders: MAINTENANCE_HEADER }));
  }
}

export const MAINTENANCE_INTERCEPTOR_INITIALIZER_PROVIDER = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: MaintenanceInterceptor,
    multi: true,
  },
];
