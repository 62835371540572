import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

declare var MathJax: {
  Hub: {
    Queue: (param: any[]) => void;
    Config: (param: any) => void;
  }
};

@Directive({
  selector: '[appMathJax]'
})
export class MathJaxDirective implements OnChanges {

  @Input('appMathJax') private value: string;

  constructor(private element: ElementRef) {}

  ngOnChanges() {
    this.element.nativeElement.innerHTML = this.value;
    MathJax.Hub.Config({
      tex2jax: {
        inlineMath: [['$', '$'], ['\\(', '\\)']],
        processEscapes: true
      }
    });
    MathJax.Hub.Queue(['Typeset', MathJax.Hub, this.element.nativeElement]);
  }
}
