import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
const marked = require('marked');
const highlight = require('highlight.js');

declare var MathJax: {
  Hub: {
    Queue: (param: any[]) => void;
    Config: (param: any) => void;
  }
};


@Directive({
  selector: '[appMarkJax]'
})
export class MarkJaxDirective implements OnChanges {

  @Input('appMarkJax') private value: string;
  @Input() private highlight = true;

  constructor(private element: ElementRef) {}

  ngOnChanges() {
    if (!this.value){
      this.element.nativeElement.innerHTML = '';
      return;
    }
    if (highlight){
      marked.setOptions({
        highlight: (code) => {
          return highlight.highlightAuto(code).value;
        }
      });
    }
    this.element.nativeElement.innerHTML = marked(this.value);
    MathJax.Hub.Config({
      tex2jax: {
        inlineMath: [['$', '$'], ['\\(', '\\)']],
        processEscapes: true
      }
    });
    MathJax.Hub.Queue(['Typeset', MathJax.Hub, this.element.nativeElement]);
  }
}
