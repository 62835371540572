import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { AppUserProfileClient } from 'src/app/core/services/api/api-service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss'],
})
export class UserSearchComponent implements OnInit, OnDestroy {
  subs = new SubSink();
  searchedUser$ = new Subject<string>();
  foundUsers: any[] | undefined = undefined;

  constructor(private _appUserProfileClient: AppUserProfileClient) {}

  ngOnInit(): void {
    this._listenToSearches();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  searchUsers(event): void {
    event.stopPropagation();
    this.searchedUser$.next(event.target.value);
  }

  private _listenToSearches(): void {
    this.subs.sink = this.searchedUser$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((searchedKey: string) => {
          return this._appUserProfileClient.searchUser(searchedKey);
        })
      )
      .subscribe(searchResult => {
        this.foundUsers = searchResult;
      });
  }
}
