<!--begin::Subheader-->
<div class="subheader py-2 py-lg-12" [ngClass]="subheaderCSSClasses" id="kt_subheader" *ngIf='(title$ | async) || (description$ | async)'>
  <div [ngClass]="subheaderContainerCSSClasses"
    class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
    <!--begin::Info-->
    <div class="d-flex align-items-center flex-wrap mr-1">
      <ng-container *ngIf="subheaderMobileToggle">
        <!--begin::Mobile Toggle-->
        <button class="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none" id="kt_subheader_mobile_toggle">
          <span></span>
        </button>
        <!--end::Mobile Toggle-->
      </ng-container>

      <!--begin::Page Heading-->
      <div class="d-flex flex-column">
        <!--begin::Page Title-->
        <ng-container *ngIf="title$ | async as _title">
          <h2 class="text-white font-weight-bold my-2 mr-5">
            {{ _title | abpLocalization }}
            <ng-container *ngIf="subheaderDisplayDesc">
              <ng-container *ngIf="description$ | async as _description">
                <small>{{ _description | abpLocalization }}</small>
              </ng-container>
            </ng-container>
          </h2>
        </ng-container>
        <!--end::Page Title-->
        <!--begin::Breadcrumb-->
        <ng-container *ngIf="breadcrumbs$ | async as breadcrumbs">
          <div class="d-flex align-items-center font-weight-bold my-2">
            <a href="/" class="opacity-75 hover-opacity-100">
              <i class="flaticon2-shelter text-white icon-1x"></i>
            </a>
            <ng-container *ngFor="let bc of breadcrumbs">
              <span class="label label-dot label-sm bg-white opacity-75 mx-3"></span>
              <a [routerLink]="bc.linkPath" routerLinkActive="active"
                class="text-white text-hover-white opacity-75 hover-opacity-100">
                {{ bc.linkText | abpLocalization }}
              </a>
            </ng-container>
          </div>
        </ng-container>
        <!--end::Breadcrumb-->
      </div>
      <!--end::Page Heading-->
    </div>
    <!--end::Info-->

  </div>
</div>
<!--end::Subheader-->
