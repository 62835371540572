import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: [],
})
export class NotificationComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
