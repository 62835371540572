<div class="container d-flex flex-column flex-md-row align-items-center justify-content-between">
  <div class="text-dark order-2 order-md-1">
    algoleague © All Rights Reserved {{ currentYear }} - {{ currentVersion }}
  </div>
  <div class="nav nav-dark order-1 order-md-2">
    <a routerLink="/page/about" target="_blank" class="nav-link pr-3 pl-0">{{
      '::About' | abpLocalization
    }}</a>
    <a routerLink="/page/help" target="_blank" class="nav-link px-3">{{
      '::Help' | abpLocalization
    }}</a>
    <a routerLink="/page/legal" target="_blank" class="nav-link pl-3">{{
      '::Legal' | abpLocalization
    }}</a>
    <a routerLink="/page/contact-us" target="_blank" class="nav-link pl-3 pr-0">{{
      '::ContactUs' | abpLocalization
    }}</a>
  </div>
</div>
