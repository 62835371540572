import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  template: ` <div id="maintenance__section">
    <img src="assets/media/photos/maintenance.svg" />
  </div>`,
  styles: [
    `
      #maintenance__section {
        height: 100vh;
        width: 100vw;
        overflow: hidden;
        background-color: #130a4d;
      }
      #maintenance__section img {
        height: 100%;
        width: 100%;
        max-width: 100%;
        max-height: 100%;
      }
    `,
  ],
})
export class MaintenanceComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    const splashScreen = document.getElementById('splash-screen');
    if (splashScreen) {
      splashScreen.style.opacity = '0';
    }
  }
}
