import { Component, OnInit, Renderer2 } from '@angular/core';
import { LayoutService } from '../../../core';
import { RoutesComponent } from '@abp/ng.theme.basic';
import { Location } from '@angular/common';
import { RoutesService } from '@abp/ng.core';

@Component({
  selector: 'app-header-menu-dynamic',
  templateUrl: './header-menu-dynamic.component.html',
  styleUrls: ['./header-menu-dynamic.component.scss']
})
export class HeaderMenuDynamicComponent extends RoutesComponent implements OnInit {
  ulCSSClasses: string;
  rootArrowEnabled: boolean;
  location: Location;
  headerMenuDesktopToggle: string;

  constructor(private layout: LayoutService, private loc: Location, router: RoutesService, renderer2: Renderer2) {
    super(router, renderer2);
    this.location = this.loc;
  }

  ngOnInit(): void {
    this.ulCSSClasses = this.layout.getStringCSSClasses('header_menu_nav');
    this.rootArrowEnabled = this.layout.getProp('header.menu.self.rootArrow');
    this.headerMenuDesktopToggle = this.layout.getProp(
      'header.menu.desktop.toggle'
    );
  }
}
