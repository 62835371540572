import { Directive, Input, ElementRef, Renderer2, OnInit } from '@angular/core';

@Directive({
  selector: '[appScoreLevelBgColor]',
})
export class ScoreLevelBgColorDirective implements OnInit {
  @Input() publicScore: number;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this._setBackgroundColor();
  }

  private _setBackgroundColor(): void {
    let bgClass = 'none';
    if (this.publicScore > 0 && this.publicScore < 250) {
      bgClass = 'beginner';
    }
    if (this.publicScore >= 250 && this.publicScore < 500) {
      bgClass = 'intermediate';
    }
    if (this.publicScore >= 500 && this.publicScore < 750) {
      bgClass = 'advanced';
    }
    if (this.publicScore >= 750) {
      bgClass = 'sensei';
    }
    this.renderer.addClass(this.el.nativeElement, bgClass);
  }
}
