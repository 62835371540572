import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { findByKey } from '../../utilities/find-by-key';
import { LocalizationService } from '@abp/ng.core';

@Directive({
  selector: '[appFindObject]'
})
export class FindObjectDirective implements OnChanges {

  @Input('appFindObject') private obj: any;
  @Input() key: 'message';
  @Input() deep = 1;

  constructor(private element: ElementRef, private localizationService: LocalizationService) { }

  ngOnChanges(changes: SimpleChanges): void {
    const results: any[] = [];
    findByKey(this.obj, this.key, this.deep, results);
    this.localizationService.get(results[results.length - 1]).subscribe(data => {
      this.element.nativeElement.innerHTML = data;
    });
  }
}
