<!--begin::Languages-->
<div class="dropdown" ngbDropdown placement="bottom-right" [autoClose]="true" *ngIf="(dropdownLanguages$ | async)?.length > 0">
  <!--begin::Toggle-->
  <div
    ngbDropdownToggle
    class="topbar-item"
    data-toggle="dropdown"
    data-offset="10px,0px"
  >
    <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 bg-transparent-h">
      <!--<img
        class="h-25px w-25px rounded"
        src="{{ language?.flag }}"
        alt="language"
      />-->
      {{ defaultLanguage$ | async }}
    </div>
  </div>
  <!--end::Toggle-->

  <!--begin::Dropdown-->
  <div
    ngbDropdownMenu
    class="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-sm dropdown-menu-right"
  >
    <ul class="navi navi-hover py-4">
      <ng-container *ngFor="let language of dropdownLanguages$ | async">
        <li class="navi-item" [ngClass]="{ active: language.cultureName === (defaultLanguage$ | async) }">
          <a
            href="javascript:;"
            (click)="onChangeLanguage(language.cultureName)"
            [ngClass]="{ active: language.cultureName === (defaultLanguage$ | async) }"
            class="navi-link"
          >
            <!--<img
              class="h-25px w-25px rounded"
              src="{{ language?.flag }}"
              alt="language"
            />-->
            <span class="navi-text">{{ language.displayName }}</span>
          </a>
        </li>
      </ng-container>
    </ul>
  </div>
  <!--end::Dropdown-->
</div>
<!--end::Languages-->
