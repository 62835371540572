import { Component, OnDestroy, OnInit } from '@angular/core';
import { LayoutService } from '../../../core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent implements OnInit, OnDestroy {
  ulCSSClasses: string;
  subscriptions: Subscription[] = [];
  url: string;

  constructor(
    private layout: LayoutService,
    private oAuthService: OAuthService,
    private router: Router
  ) {
    this.subscriptions.push(
      router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.url = event.url;
        }
      })
    );
  }

  ngOnInit(): void {
    this.url = this.router.url;
    this.ulCSSClasses = this.layout.getStringCSSClasses('header_menu_nav');
  }

  get isUserLoggedIn(): boolean {
    return this.oAuthService.hasValidAccessToken();
  }

  isInHomeArea(): boolean {
    return this.url === '/';
  }

  isInPracticeArea(): boolean {
    return this.url.startsWith('/problem');
  }

  isInContestArea(): boolean {
    return this.url.startsWith('/contest');
  }

  isInGeneralLeadboardArea(): boolean {
    return this.url.startsWith('/leaderboard');
  }

  isInTrainingArea(): boolean {
    return this.url.startsWith('/training');
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
