import { SubSink } from 'subsink';
import { fromEvent, Observable } from 'rxjs';
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { LayoutService } from '../../../_metronic/core';
import KTLayoutHeaderTopbar from '../../../../../../assets/js/layout/base/header-topbar';

@Component({
  selector: 'app-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss'],
})
export class HeaderMobileComponent implements OnInit, AfterViewInit, OnDestroy {
  headerLogo = '';
  asideSelfDisplay = true;
  headerMenuSelfDisplay = true;
  headerMobileClasses = '';
  headerMobileAttributes = {};
  clickObservable: Observable<Event> = fromEvent(document, 'click');
  private _subs = new SubSink();
  constructor(private layout: LayoutService) {}

  ngOnInit(): void {
    // build view by layout config settings
    this.headerMobileClasses = this.layout.getStringCSSClasses('header_mobile');
    this.headerMobileAttributes = this.layout.getHTMLAttributes('header_mobile');

    this.headerLogo = this.getLogoUrl();
    this.asideSelfDisplay = this.layout.getProp('aside.self.display');
    this.headerMenuSelfDisplay = this.layout.getProp('header.menu.self.display');
    this._listenToClickEvent();
  }

  ngAfterViewInit() {
    // Init Header Topbar For Mobile Mode
    KTLayoutHeaderTopbar.init('kt_header_mobile_topbar_toggle');
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  private getLogoUrl() {
    const headerSelfTheme = this.layout.getProp('header.self.theme') || '';
    const brandSelfTheme = this.layout.getProp('brand.self.theme') || '';
    let result = 'logo-white.svg';
    if (!this.asideSelfDisplay) {
      if (headerSelfTheme === 'light') {
        result = 'logo-white.svg';
      }
    } else {
      if (brandSelfTheme === 'light') {
        result = 'logo-white.svg';
      }
    }
    return `./assets/media/svg/logos/${result}`;
  }

  private _listenToClickEvent(): void {
    this._subs.sink = this.clickObservable.subscribe((event: any) => {
      const kt_header_menu_wrapper = document.getElementById('kt_header_menu_wrapper');

      if (
        kt_header_menu_wrapper &&
        (event.target.id == 'kt_header_mobile_toggle' ||
          event.target.id == 'kt_header_mobile_toggle_span')
      ) {
        kt_header_menu_wrapper.classList.add('header-menu-wrapper-on');
      }

      const kt_body = document.getElementById('kt_body');
      if (
        kt_body &&
        (event.target.id == 'kt_header_mobile_topbar_toggle' ||
          event.target.id == 'kt_header_mobile_topbar_toggle__image')
      ) {
        const topBarMenuIsVisible = kt_body.classList.contains('topbar-mobile-on');
        topBarMenuIsVisible
          ? kt_body.classList.remove('topbar-mobile-on')
          : kt_body.classList.add('topbar-mobile-on');
      }
    });
  }
}
