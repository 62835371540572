import { Injectable } from '@angular/core';
import { createApi } from 'unsplash-js';
import { EnvironmentService } from '@abp/ng.core';
import { LayoutService } from '../../../shared/templates/_metronic/core';

@Injectable({
  providedIn: 'root'
})
export class UnsplashService {

  constructor(private environmentService: EnvironmentService, private layoutService: LayoutService) { }

  getImage(): Promise<string> {
    const environment: any = this.environmentService.getEnvironment();
    const api = createApi({
      accessKey: environment.unsplashClientId,
    });
    return api.photos.getRandom({collectionIds: [environment.unsplashCollectionId]}).then((data) => {
      const response: any = data.response;
      this.layoutService.imageCreator = response.user.name;
      return response.urls.regular;
    });
  }
}
