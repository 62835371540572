import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Base64ToFile } from '../../utilities/Base64ToFile';
import { FormInit } from '../../abstracts/form-init';
import { EnvironmentService } from '@abp/ng.core';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent extends FormInit implements OnInit {
  file: File;
  croppedFile: File;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<ImageCropperComponent>,
              environmentService: EnvironmentService) {
    super(environmentService);
    if (data && data.file) {
      this.file = data.file;
    }
  }

  ngOnInit(): void {
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedFile = Base64ToFile(event.base64, this.file.name);
  }

  save(): void {
    this.dialogRef.close({data: this.croppedFile});
  }
}
