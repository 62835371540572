import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
  /**
   * default value is true
   */
  @Input() isFullScreenSpinner = true;

  /**
   * If this property value set to true user can't
   *  select any element of page
   */
  @Input() blockUI = true;

  constructor() {}
  ngOnInit(): void {}
}
