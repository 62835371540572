import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SplashScreenService } from '../../partials/layout/splash-screen/splash-screen.service';
import { LayoutInitService } from '../../core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-empty-layout',
  templateUrl: './empty-layout.component.html',
  styleUrls: ['./empty-layout.component.scss']
})
export class EmptyLayoutComponent implements OnInit, OnDestroy {
  private unsubscribe: Subscription[] = [];

  constructor(private splashScreenService: SplashScreenService, private router: Router, initService: LayoutInitService) {
    initService.init();
  }

  ngOnInit(): void {
    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);
      }
    });
    this.unsubscribe.push(routerSubscription);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
