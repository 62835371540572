import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateMinimal'
})
export class DateMinimalPipe implements PipeTransform {

  transform(value: string, ...args: any[]): string {
    return value
      .replace(',', ' ')
      .replace('years', 'y')
      .replace('year', 'y')
      .replace('weeks', 'w')
      .replace('week', 'w')
      .replace('days', 'd')
      .replace('day', 'd')
      .replace('hours', 'h')
      .replace('hour', 'h')
      .replace('minutes', 'm')
      .replace('minute', 'm')
      .replace('seconds', 's')
      .replace('second', 's');
  }

}
