import { Directive, Input, ElementRef, Renderer2, OnInit } from '@angular/core';

@Directive({
  selector: '[appScoreLevelTextColor]',
})
export class ScoreLevelTextColorDirective implements OnInit {
  @Input() publicScore: number;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this._setTextColor();
  }

  private _setTextColor(): void {
    let textClass = 'none';
    if (this.publicScore > 0 && this.publicScore < 250) {
      textClass = 'beginner-level-text-color';
    }
    if (this.publicScore >= 250 && this.publicScore < 500) {
      textClass = 'intermediate-level-text-color';
    }
    if (this.publicScore >= 500 && this.publicScore < 750) {
      textClass = 'advanced-level-text-color';
    }
    if (this.publicScore >= 750) {
      textClass = 'sensei-level-text-color';
    }
    this.renderer.addClass(this.el.nativeElement, textClass);
  }
}
