export function findByKey(obj: any, key: string, deep: number, results: any[] = []): void {
  if (obj[key]) {
    results.push(obj[key]);
    if (results.length >= deep) {
      return;
    }
  }
  let p: any;
  for (p in obj) {
    if (obj.hasOwnProperty(p) && typeof obj[p] === 'string') {
      try {
        const jObj = JSON.parse(obj[p]);
        if (jObj[key]) {
          results.push(jObj[key]);
          if (results.length >= deep) {
            return;
          }
        }
        let j;
        for (j in jObj) {
          if (jObj.hasOwnProperty(j) && typeof jObj[j] === 'object') {
            findByKey(jObj[j], key, deep, results);
          }
        }
      } catch (err) {
        if (p !== 'content-type') {
          results.push(obj[p]);
        }
        if (results.length >= deep) {
          return;
        }
      }
    } else if (obj.hasOwnProperty(p) && typeof obj[p] === 'object') {
      try {
        findByKey(obj[p], key, deep, results);
      } catch (err) {
        // It's Ok
      }
    }
  }
  return;
}
