import { EnvironmentService } from '@abp/ng.core';

export abstract class FormInit {
  minLengthShortText: number;
  maxLengthShortText: number;
  minLengthLongText: number;
  maxLengthLongText: number;
  widthProfilePicture: number;
  heightProfilePicture: number;
  minDateBirthDay: Date;
  maxDateBirthDay: Date;
  recaptchaSiteKey: string;

  protected constructor(environmentService: EnvironmentService) {
    const env: any = environmentService.getEnvironment();
    this.minLengthShortText = env.validation.minLengthShortText;
    this.maxLengthShortText = env.validation.maxLengthShortText;
    this.minLengthLongText = env.validation.minLengthLongText;
    this.maxLengthLongText = env.validation.maxLengthLongText;
    this.widthProfilePicture = env.validation.widthProfilePicture;
    this.heightProfilePicture = env.validation.heightProfilePicture;
    this.maxDateBirthDay = new Date(
      new Date().setFullYear(new Date().getFullYear() - env.validation.minAgeOfUser)
    );
    this.minDateBirthDay = new Date(
      new Date().setFullYear(new Date().getFullYear() - env.validation.maxAgeOfUser)
    );
    this.recaptchaSiteKey = env.recaptchaSiteKey;
  }
}
